// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { MessageWinBannerProps } from '../../global.d';
import { isBaseGameMode, updateCoinValueAfterBonuses } from '../../utils';
import Tween from '../animations/tween';
import Arrow from '../arrow';
import Heart from '../arrow/heart';
import bgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  EventTypes,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { GameMode } from '../config/bonusInfo';
import { IGameContainer } from '../d';
import ExpandSymbol from '../expandSymbol';
import { FreeSpinsCounter } from '../freeSpinCounter/freeSpinCounter';
import FreeSpinUiFlash from '../freeSpinUiFlash/freeSpinUiFlash';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import Meter from '../meter';
import ReelsFrame from '../reels/frame/reelsFrame';
import ReplayText from '../replay/replayText';
import SpSymbolLog from '../spSymbolLot/spSymbolLot';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  private messageWinBanner: MessageWinBanner | undefined;

  private meter: Meter;

  private arrow: Arrow;

  private heart: Heart;

  private freeSpinUiFlash: FreeSpinUiFlash;

  private expandSymbol: ExpandSymbol;

  private spSymbolLog: SpSymbolLog;

  private freeSpinsCounter: FreeSpinsCounter;

  private replayText: ReplayText;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.spSymbolLog = new SpSymbolLog();
    this.freeSpinsCounter = new FreeSpinsCounter();
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        (SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT) / 2,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();

    this.frame = new ReelsFrame();
    this.expandSymbol = new ExpandSymbol();
    this.arrow = new Arrow();
    this.meter = new Meter();
    this.heart = new Heart();
    this.freeSpinUiFlash = new FreeSpinUiFlash();
    this.replayText = new ReplayText();

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.frame);
    this.addChild(this.expandSymbol);
    this.addChild(this.replayText);
    this.addChild(this.gameTitle);

    this.addChild(this.freeSpinsCounter);

    this.addChild(this.meter);
    this.addChild(this.arrow);
    this.addChild(this.heart);
    this.addChild(this.freeSpinUiFlash);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.spSymbolLog);

    eventManager.addListener(EventTypes.SET_REEL_FRAME_VISIBLE, this.reelFrameVisible.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const totalWinDelay = Tween.createDelayAnimation(1000);
    totalWinDelay.addOnComplete(() => {
      bgmControl.fadeOutAll(100);
      this.messageWinBanner = new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init();
      this.messageWinBanner.visible = false;
      this.addChild(this.messageWinBanner);
      this.messageWinBanner.updateTransform();
      this.messageWinBanner.visible = true;
      AudioApi.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    });

    totalWinDelay.start();
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isBaseGameMode(settings.mode)) {
      updateCoinValueAfterBonuses();
    }
  }

  private reelFrameVisible(visible: boolean): void {
    this.frame.visible = visible;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
