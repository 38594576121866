import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../global.d';
import { setIsOpenHistoryPopup, setIsPopupOpeningInProgress, setIsReplay } from '../../gql/cache';
import { configGql } from '../../gql/query';

import HistoryComponent from './HistoryComponent';

const HistoryPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [visible, setVisible] = useState(true);
  const { data: configData } = useQuery(configGql);
  const { t } = useTranslation();
  const { isOpenHistoryPopup, isHistoryVisible } = configData!;

  useEffect(() => {
    if (isOpenHistoryPopup) {
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenHistoryPopup);
  }, [isOpenHistoryPopup]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  useEffect(() => {
    setVisible(isHistoryVisible);
  }, [isHistoryVisible]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      (setIsPopupOpeningInProgress() &&
        setIsPopupOpeningInProgress() !== PopupOpeningTypes.HISTORY_POPUP &&
        setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_FEATURE_POPUP &&
        !directClickToCloseIcon) ||
      setIsReplay()
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenHistoryPopup(false);
  };

  return (
    <Popup
      id="popup"
      className={visible ? 'popup' : 'popup-hidden'}
      showNow={showPopup}
      setShowNow={() => handleClose()}
    >
      <div className="popup__title">{t('gameHistory')}</div>
      <Button intent="close" className="popup__close" onClick={handleClose} />
      <HistoryComponent />
    </Popup>
  );
};

export default HistoryPopup;
