import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils/helper';
import ViewContainer from '../components/container';
import {
  EventTypes,
  GAME_TITLE_LOGO_LANDSCAPE_Y_POSITION,
  GAME_TITLE_LOGO_PORTRAIT_Y_POSITION,
  GAME_TITLE_LOGO_X_POSITION,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { GameMode } from '../config/bonusInfo';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));
  private isPortrait: boolean;
  private isFeature: boolean;

  constructor() {
    super();

    this.isPortrait = false;
    this.isFeature = false;

    this.initGameLogo();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0.5);
    this.addChild(this.titleLogo);
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.isFeature = true;
      this.visible = false;
    } else {
      this.isFeature = false;
      this.visible = true;
    }

    if (this.isFeature && !this.isPortrait) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private resize(width: number, height: number): void {
    this.titleLogo.x = SLOTS_CONTAINER_WIDTH / 2 + GAME_TITLE_LOGO_X_POSITION;
    if (width > height) {
      this.isPortrait = false;
      this.titleLogo.y = SLOTS_CONTAINER_HEIGHT / 2 + GAME_TITLE_LOGO_LANDSCAPE_Y_POSITION;
    } else {
      this.isPortrait = true;
      this.titleLogo.y = SLOTS_CONTAINER_HEIGHT / 2 + GAME_TITLE_LOGO_PORTRAIT_Y_POSITION;
    }

    if (this.isFeature && !this.isPortrait) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default GameTitle;
