import _ from 'lodash';

import { SlotId } from '../../config';
import { ReelSet } from '../../global.d';
import { REELS_AMOUNT } from '../../slotMachine/config';
import { Icon } from '../../slotMachine/d';

import { getIconById } from './helper';

export const getSpinResult5x3 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index]![random]!);
    }),
    ...reelPositions.map((random, index) => {
      const prevRandom = random + 1 >= reelSet.layout[index]!.length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index]![prevRandom]!);
    }),
    ...reelPositions.map((random, index) => {
      const prev2Random =
        random + 2 >= reelSet.layout[index]!.length ? random + 2 - reelSet.layout[index]!.length : random + 2;
      return getIconById(icons, reelSet.layout[index]![prev2Random]!);
    }),
  ];
  return spinResult;
};

export const getScatterPosition = (spinResult: Icon[]): number[] => {
  const count: number[] = [];

  for (let i = 0; i < spinResult.length; i++) {
    if (spinResult[i]!.id === SlotId.WL) {
      count.push(i);
    }
  }
  return count;
};

export const getExpandSymbolCount = (spinResult: Icon[], spSlotId: SlotId | undefined): number[] => {
  let count = 0;
  return _(spinResult)
    .chunk(REELS_AMOUNT)
    .unzip()
    .map((col) => {
      if (col.some((icon) => icon.id === spSlotId)) {
        count += 1;
        return count;
      }
      return 0;
    })
    .value();
};

export const getExpandSymbolPosition = (spinResult: Icon[], spSlotId: SlotId | undefined): number[] => {
  const count: number[] = [0, 0, 0, 0, 0];
  let j = 0;

  for (let i = 0; i < spinResult.length; i++) {
    if (spinResult[i]!.id === spSlotId) {
      count[j] = i;
    }
    j++;
    if (j === REELS_AMOUNT) {
      j = 0;
    }
  }
  return count;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const getScatterCount = (spinResult: Icon[]): number[] => {
  let count = 0;
  return _(spinResult)
    .chunk(REELS_AMOUNT)
    .unzip()
    .map((col) => {
      if (col.some((icon) => icon.id === SlotId.WL)) {
        count += 1;
        return count;
      }
      return 0;
    })
    .value();
};
