import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { setBrokenGame, setNextResult, setUserLastBetResult } from '../../gql/cache';
import { getAddHeartCount, getGameModeByBonusId, getSpSymbolByBonusId } from '../../utils/helper';
import AnimationChain from '../animations/animationChain';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import ViewContainer from '../components/container';
import { EventTypes, eventManager } from '../config';
import { GameMode } from '../config/bonusInfo';
import { multiPlierStyle } from '../gameView/config';

import {
  HEART_COUNT_UP_DELAY,
  METER_ANIMATION_NAME,
  METER_MAX,
  METER_TABLE,
  MULTIPLIER_UP_BEFORE_DELAY,
  MULTIPLIER_UP_DELAY,
  MeterKind,
  meterPram,
} from './config';

class Meter extends ViewContainer {
  private meterAnimation: SpineAnimation | undefined = undefined;

  private meterAnimationStep = 0;

  private multiPlierText = new PIXI.Text('x1', multiPlierStyle);

  private meterKind = MeterKind.SlotId_C_F;

  private tmpHeartCount = 0;
  private tmpHeartCountOld = 0;

  private multiPlierNum = 0;
  private multiPlierNumOld = 0;

  private addHeartCount = 0;

  private meterHeart = 0;

  constructor() {
    super();
    this.init();

    if (
      setBrokenGame() &&
      !(getGameModeByBonusId(setUserLastBetResult()!.data.features.gameRoundStore.bonusId) === GameMode.REGULAR)
    ) {
      this.resumeMeter(
        getSpSymbolByBonusId(setUserLastBetResult()!.data.features.gameRoundStore.bonusId),
        setUserLastBetResult()!.data.features.gameRoundStore.multiplier,
        setUserLastBetResult()!.data.features.gameRoundStore.heart!,
      );
    }
  }

  private init() {
    this.containerInit();
    this.eventInit();
    this.multiPlierText.resolution = 1;
    this.multiPlierText.position.set(meterPram.TEXT_POS_X, meterPram.TEXT_POS_Y);
    this.multiPlierText.visible = false;
    this.initAnimation();
  }

  private containerInit() {
    this.visible = false;
    this.position.set(meterPram.CONTAINER_LANDSCAPE_POS_X, meterPram.CONTAINER_LANDSCAPE_POS_Y);
  }

  private eventInit() {
    eventManager.addListener(EventTypes.INIT_METER_SET, this.initMeterSetEvent.bind(this));
    eventManager.addListener(EventTypes.SET_METER, this.setMeterCount.bind(this));
    eventManager.addListener(EventTypes.SET_METER_MULTIPLIER, this.setMeterMultiplier.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_SHOW, this.setDisplay.bind(this));
    eventManager.addListener(EventTypes.START_METER_ADD_COUNT, this.createCountUpAnimations.bind(this));
    eventManager.addListener(EventTypes.METER_ADD_HEART, this.addHeart.bind(this));
    eventManager.addListener(EventTypes.REPLAY_METER, this.replayMeter.bind(this));
  }

  private initMeterSetEvent(setMultiPlierNum: number, setKind: MeterKind, isResume?: boolean) {
    this.meterKind = setKind;
    this.meterAnimationStep = 0;
    this.multiPlierText.position.set(meterPram.TEXT_POS_X, meterPram.TEXT_POS_Y);
    if (this.meterKind === MeterKind.SlotId_D_J || this.meterKind === MeterKind.SlotId_B_H_G) {
      if (isResume) {
        this.multiPlierNum = setMultiPlierNum;
        this.setMeterMultiplier(setMultiPlierNum);
      } else {
        this.multiPlierNum = 1;
        this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
        if (setMultiPlierNum) this.setMeterMultiplier(1);
      }
    } else if (this.meterKind === MeterKind.SlotId_C_F) {
      this.multiPlierNum = setMultiPlierNum;
      this.multiPlierText.position.set(meterPram.TEXT_POS_X, meterPram.TEXT_POS_Y_SLOT_ID_C_F);
      this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
      if (setMultiPlierNum) this.setMeterMultiplier(setMultiPlierNum);
    } else {
      this.multiPlierNum = setMultiPlierNum;
      if (setMultiPlierNum) this.setMeterMultiplier(setMultiPlierNum);
    }
    this.addChild(this.multiPlierText);
  }

  private initAnimation() {
    this.meterAnimation = new SpineAnimation({}, METER_ANIMATION_NAME);
  }

  private startAnimation(anim: SpineInterface['freespin_meter']['animations']) {
    this.meterAnimationStep++;
    this.addChild(this.meterAnimation!.spine);
    this.addChild(this.multiPlierText);
    this.meterAnimation!.setAnimation(anim, true);
  }

  private addHeart(initScatterCount: boolean, scatterCount: number[], expandCount: number[]): void {
    const SCCountMax = Math.max(...scatterCount);
    const expandCountMax = Math.max(...expandCount);

    if (SCCountMax) {
      this.meterHeart += SCCountMax;
    }

    if (expandCountMax) {
      this.meterHeart += 1;
    }

    if (this.meterHeart > this.remainingHeart()) {
      this.meterHeart = this.remainingHeart();
    }

    if (initScatterCount) {
      this.meterHeart = 1;
    }
  }

  private remainingHeart(): number {
    let heart = 0;
    if (this.meterKind == MeterKind.SlotId_B_H_G) {
      heart = (setNextResult()?.bet.data.features.gameRoundStore.maxMult! - this.multiPlierNumOld) * 3;
      heart -= this.tmpHeartCountOld;
    } else if (this.meterKind == MeterKind.SlotId_D_J) {
      heart = setNextResult()?.bet.data.features.gameRoundStore.maxMult! - this.multiPlierNumOld;
    }

    return heart;
  }

  private createCountUpAnimations(isInit: boolean): void {
    const animationChain = new AnimationChain();
    const prevMultiPlierNum = this.multiPlierNumOld;
    for (let i = 0; i < this.meterHeart; i++) {
      animationChain.appendAnimation(this.createCountUpAnimation(i, prevMultiPlierNum));
    }

    animationChain.addOnComplete(() => {
      this.meterHeart = 0;
      this.setMeterMultiplier(this.multiPlierNum);
      if (!isInit) {
        eventManager.emit(EventTypes.END_EXPAND_SYMBOL);
      }
    });

    animationChain.start();
  }

  private createCountUpAnimation(i: number, prevMultiPlierNum: number): AnimationChain {
    const animationChain = new AnimationChain();
    const countUpDelay = Tween.createDelayAnimation(HEART_COUNT_UP_DELAY);
    countUpDelay.addOnStart(() => {
      AudioApi.stop({ type: ISongs.MeterLit });
      AudioApi.play({ type: ISongs.MeterLit });
      this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
    });
    animationChain.appendAnimation(countUpDelay);
    if (this.meterKind === MeterKind.SlotId_B_H_G) {
      if (this.multiPlierNum != prevMultiPlierNum && (this.tmpHeartCountOld + i + 1) % 3 == 0) {
        animationChain.appendAnimation(this.createMultiplierUpAnimation());
      }
    } else if (MeterKind.SlotId_D_J) {
      if (this.multiPlierNum != prevMultiPlierNum) {
        animationChain.appendAnimation(this.createMultiplierUpAnimation());
      }
    }

    return animationChain;
  }

  private createMultiplierUpAnimation(): AnimationChain {
    const animationChain = new AnimationChain();
    const multiplierDelay = Tween.createDelayAnimation(MULTIPLIER_UP_BEFORE_DELAY);
    multiplierDelay.addOnStart(() => {
      this.startAnimation(METER_TABLE[this.meterKind][METER_TABLE[this.meterKind].length - 1]!);
      this.meterAnimationStep = 0;
    });
    animationChain.appendAnimation(multiplierDelay);
    animationChain.appendAnimation(this.createInitAnimation());
    return animationChain;
  }

  private createInitAnimation(): AnimationChain {
    const animationChain = new AnimationChain();
    const multiplierDelay = Tween.createDelayAnimation(MULTIPLIER_UP_DELAY);
    multiplierDelay.addOnStart(() => {
      this.setMeterMultiplier(++this.multiPlierNumOld);
      if (this.multiPlierNumOld === setNextResult()?.bet.data.features.gameRoundStore.maxMult) {
        this.startAnimation(METER_MAX);
        AudioApi.play({ type: ISongs.MultiUp_2 });
      } else {
        this.startAnimation(METER_TABLE[this.meterKind][0]!);
        AudioApi.stop({ type: ISongs.MultiUp_1 });
        AudioApi.play({ type: ISongs.MultiUp_1 });
      }
    });
    animationChain.appendAnimation(multiplierDelay);
    return animationChain;
  }

  private setMeterMultiplier(setMultiPlierNum: number): void {
    if (setMultiPlierNum > 0) {
      this.multiPlierText.text = 'x' + setMultiPlierNum;
      this.multiPlierText.visible = true;
    } else {
      this.multiPlierText.visible = false;
    }
  }

  private setMeterCount(setMultiPlierNum: number, setHeart: number) {
    if (setMultiPlierNum === undefined && setHeart === undefined) return;
    this.tmpHeartCountOld = this.tmpHeartCount;
    this.addHeartCount = getAddHeartCount(
      this.meterKind,
      setMultiPlierNum,
      this.multiPlierNum,
      setHeart,
      this.tmpHeartCountOld,
    );
    if (this.addHeartCount) {
      this.multiPlierNumOld = this.multiPlierNum;
      this.multiPlierNum = setMultiPlierNum;
      this.tmpHeartCount = setHeart;
    }
  }

  private changeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) {
    if (settings.mode == GameMode.REGULAR) {
      this.visible = false;
      this.multiPlierText.text = '';
      this.multiPlierText.visible = false;
      this.meterAnimationStep = 0;
      this.meterKind = MeterKind.SlotId_E_I;
      this.tmpHeartCount = 0;
      this.tmpHeartCountOld = 0;
      this.multiPlierNum = 0;
      this.multiPlierNumOld = 0;
      this.addHeartCount = 0;
      this.meterHeart = 0;
    } else {
      this.visible = false;
    }
  }

  private setDisplay(visible: boolean): void {
    if (this.meterKind === MeterKind.SlotId_E_I) {
      this.visible = false;
    } else {
      const delay = Tween.createDelayAnimation(1);
      delay.addOnComplete(() => {
        this.meterAnimation!.spine.visible = visible;
        this.visible = visible;
      });
      delay.start();
    }
  }

  private replayMeter(slotId: SlotId, multiPlier: number, heart: number, maxMult: number) {
    if (heart === undefined) {
      heart = 0;
    }

    this.tmpHeartCount = heart;
    this.tmpHeartCountOld = heart;

    this.multiPlierNum = multiPlier;
    this.multiPlierNumOld = multiPlier;

    switch (slotId) {
      case SlotId.C:
      case SlotId.F: {
        this.meterKind = MeterKind.SlotId_C_F;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_C_F, true);
        break;
      }
      case SlotId.E:
      case SlotId.I:
        multiPlier = 0;
        this.meterKind = MeterKind.SlotId_E_I;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_E_I, true);
        break;
      case SlotId.D:
      case SlotId.J: {
        this.meterKind = MeterKind.SlotId_D_J;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_D_J, true);
        this.meterAnimationStep = heart;

        if (multiPlier === maxMult) {
          this.startAnimation(METER_MAX);
        } else {
          this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
        }
        break;
      }
      case SlotId.B:
      case SlotId.H:
      case SlotId.G: {
        // if (heart === setUserLastBetResult().data.features.gameRoundStore.meter) {
        //   multiPlier += 1;
        //   heart = 0;
        // }

        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_B_H_G, true);
        this.meterAnimationStep = heart;

        if (multiPlier === maxMult) {
          this.startAnimation(METER_MAX);
        } else {
          this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
        }
        break;
      }
      default:
        break;
    }
  }

  private resumeMeter(slotId: SlotId, multiPlier: number, heart: number) {
    if (heart === undefined) {
      heart = 0;
    }

    this.tmpHeartCount = heart;
    this.tmpHeartCountOld = heart;

    this.multiPlierNum = multiPlier;
    this.multiPlierNumOld = multiPlier;

    switch (slotId) {
      case SlotId.C:
      case SlotId.F: {
        this.meterKind = MeterKind.SlotId_C_F;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_C_F, true);
        break;
      }
      case SlotId.E:
      case SlotId.I:
        multiPlier = 0;
        this.meterKind = MeterKind.SlotId_E_I;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_E_I, true);
        break;
      case SlotId.D:
      case SlotId.J: {
        if (heart === setUserLastBetResult()!.data.features.gameRoundStore.meter) {
          multiPlier += 1;
        }
        this.meterKind = MeterKind.SlotId_D_J;
        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_D_J, true);
        this.meterAnimationStep = heart;

        if (
          setUserLastBetResult()!.data.features.gameRoundStore.multiplier ===
          setUserLastBetResult()!.data.features.gameRoundStore.maxMult
        ) {
          this.startAnimation(METER_MAX);
        } else {
          this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
        }
        break;
      }
      case SlotId.B:
      case SlotId.H:
      case SlotId.G: {
        if (heart === setUserLastBetResult()!.data.features.gameRoundStore.meter) {
          multiPlier += 1;
          heart = 0;
        }

        eventManager.emit(EventTypes.INIT_METER_SET, multiPlier, MeterKind.SlotId_B_H_G, true);
        this.meterAnimationStep = heart;

        if (
          setUserLastBetResult()!.data.features.gameRoundStore.multiplier ===
          setUserLastBetResult()!.data.features.gameRoundStore.maxMult
        ) {
          this.startAnimation(METER_MAX);
        } else {
          this.startAnimation(METER_TABLE[this.meterKind][this.meterAnimationStep]!);
        }
        break;
      }
      default:
        break;
    }
  }
}

export default Meter;
