import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'game ID',
    },
  },
  specialPayTable: [
    {
      slug: ResourceTypes.symbolA,
      awardText: 'infoABonus',
    },
  ],
  miniPayTableData: [
    {
      slug: ResourceTypes.symbolA,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolB,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolC,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolD,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolE,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolF,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolG,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolH,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolI,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJ,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
  scatterTableData: [
    {
      slug: ResourceTypes.symbolA,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
  payTableData: [
    {
      slug: ResourceTypes.symbolB,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolC,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolD,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolE,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolF,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolG,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolH,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolI,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJ,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
  ExpandTableData: [
    {
      slug: ResourceTypes.symbolBSpecial,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 500 },
        { pattern: 'x3', multiplier: 50 },
        { pattern: 'x2', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolCSpecial,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 20 },
        { pattern: 'x2', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolDSpecial,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolESpecial,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolFSpecial,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolGSpecial,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 10 },
      ],
    },
    {
      slug: ResourceTypes.symbolHSpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolISpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.symbolJSpecial,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
  ],
};

export enum SlotId {
  WL = 'WL', // BOOK
  B = 'B', // High1
  C = 'C', // High2
  D = 'D', // High3
  E = 'E', // High4
  F = 'F', // Low1
  G = 'G', // Low2
  H = 'H', // Low3
  I = 'I', // Low4
  J = 'J', // Low5
}

export enum Colors {
  WHITE_COLOR = 0xffffff,
  GAME_COLOR = 0xffcc48,
}

export enum Variables {
  FONT_FAMILY = 'NotoSans-SemiCondensedBold',
}
