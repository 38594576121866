import { SlotId } from '../../config';
import SpineAnimation from '../../slotMachine/animations/spine';
import { PAY_LINES } from '../../slotMachine/config';
import { BonusDataByBonusId, GameMode } from '../../slotMachine/config/bonusInfo';
import { Icon } from '../../slotMachine/d';
import { nextTick } from '../utils/utils';

import { isFreeSpinsMode } from './odin';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || PAY_LINES)) / 100;
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  const gameMode = BonusDataByBonusId[bonusId]?.gameMode;
  return gameMode ? gameMode : GameMode.REGULAR;
};

export const getSpSymbolByBonusId = (bonusId: string): SlotId => {
  const spSymbol = BonusDataByBonusId[bonusId]?.spSymbol;
  return spSymbol ? spSymbol : SlotId.WL;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

/**
 * ⚠️CAUTION⚠️
 * This function ignores null checks and is unsafe
 * @param value Nullable value
 * @returns value typecast to NonNullable
 */
export function getNonNullableValue<T>(value: T): RecursiveNonNullable<T> {
  return value as RecursiveNonNullable<T>;
}

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isCountUp,
  isBuyFeaturePopupOpened,
  isAutoPlay,
  isFadeOut,
  isBrokenBuyFeature,
  isPhoenix,
  isReplay,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isCountUp: boolean;
  isBuyFeaturePopupOpened: boolean;
  isAutoPlay: boolean;
  isFadeOut: boolean;
  isBrokenBuyFeature: boolean;
  isPhoenix: boolean;
  isReplay: boolean;
}): boolean => {
  if (isPhoenix) {
    return false;
  }

  if (isFadeOut) {
    return false;
  }

  if (isBrokenBuyFeature) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  if (isOpenedMessageBanner) {
    return true;
  }

  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isCountUp) {
    return true;
  }

  if (isReplay) {
    return true;
  }

  if (isFreeSpinsMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  return true;
};
