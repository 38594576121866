import * as PIXI from 'pixi.js';

import { MAPPED_SP_SYMBOLS, SlotId } from '../../config';
import { setBrokenGame, setCurrentBonus } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import { BgSkin } from '../background/config';
import ViewContainer from '../components/container';
import {
  EventTypes,
  FREE_SPIN_COUNTER_SPIN_X,
  FREE_SPIN_COUNTER_SPIN_Y,
  FREE_SPIN_COUNTER_SP_SYMBOL_X,
  FREE_SPIN_COUNTER_SP_SYMBOL_Y,
  FREE_SPIN_COUNTER_TITLE_X,
  FREE_SPIN_COUNTER_TITLE_Y,
  FREE_SPIN_COUNTER_X,
  FREE_SPIN_COUNTER_Y,
  eventManager,
} from '../config';
import { GameMode } from '../config/bonusInfo';
import { spinsStyle, textStyle } from '../gameView/config';

import {
  FREE_SPINS_COUNTER_ANIMATION_DELAY,
  FREE_SPINS_COUNTER_ANIMATION_LOOP,
  FREE_SPINS_COUNTER_ANIMATION_SCALE,
} from './config';

export class FreeSpinsCounter extends ViewContainer {
  private titleText: PIXI.Text;

  private spinsText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spriteSpSymbol: PIXI.Sprite;

  private spriteFreeSpinFrame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.freespinFrame));

  constructor() {
    super();

    this.spriteSpSymbol = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolB));
    this.titleText = this.initTitleText(i18n.t('freeSpins'));
    this.spinsText = this.initSpinsText(0, 0);

    this.init();

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
      (spins: number, curr: number, immediately: boolean): void => this.handleUpdate(spins, curr, immediately),
    );

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SET_SP_SYMBOL, this.setSpSymbol.bind(this));
    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_SHOW, this.setDisplay.bind(this));

    if (setBrokenGame()) {
      this.handleUpdate(setCurrentBonus().rounds, setCurrentBonus().currentRound, true);
    }
  }

  private init(): void {
    this.addChild(this.spriteFreeSpinFrame);
    this.addChild(this.titleText);
    this.addChild(this.spinsText);
    this.addChild(this.spriteSpSymbol);
    this.pivot.set(this.width / 2, 0);
    this.position.set(FREE_SPIN_COUNTER_X, FREE_SPIN_COUNTER_Y);
    this.visible = false;

    this.spriteSpSymbol.anchor.set(0.5);
    this.spriteSpSymbol.scale.set(0.5);
    this.spriteSpSymbol.position.set(FREE_SPIN_COUNTER_SP_SYMBOL_X, FREE_SPIN_COUNTER_SP_SYMBOL_Y);

    this.titleText.anchor.set(0.5);
    this.titleText.position.set(FREE_SPIN_COUNTER_TITLE_X, FREE_SPIN_COUNTER_TITLE_Y);

    this.spinsText.anchor.set(0.5);
    this.spinsText.position.set(FREE_SPIN_COUNTER_SPIN_X, FREE_SPIN_COUNTER_SPIN_Y);
  }

  private initTitleText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(titleText, textStyle);
    text.resolution = 1;
    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): PIXI.Text {
    const spinsText = new PIXI.Text(this.formatSpins(spins, currentSpin), spinsStyle);
    spinsText.resolution = 1;
    return spinsText;
  }

  private handleUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    // if (!isFirst && currentSpin === 0) {
    //   const delay = Tween.createDelayAnimation(1000);
    //   delay.addOnComplete(() => {
    //     currentSpin += 1;
    //     this.handleUpdate(spins, currentSpin, immediately);
    //   });
    //   delay.start();
    // }
    this.spinsText.text = this.formatSpins(spins, currentSpin);

    if (immediately) return;
    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPINS_COUNTER_ANIMATION_SCALE,
      FREE_SPINS_COUNTER_ANIMATION_DELAY,
      FREE_SPINS_COUNTER_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private setSpSymbol(slotId: SlotId | undefined): void {
    this.spriteSpSymbol.texture = slotId
      ? PIXI.Texture.from(MAPPED_SP_SYMBOLS[slotId])
      : PIXI.Texture.from(MAPPED_SP_SYMBOLS[SlotId.WL]);
  }

  private formatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin}/${spins}`;
  }

  private onChangeMode(settings: { mode: GameMode; _background?: BgSkin }): void {
    if (settings.mode === GameMode.REGULAR) {
      this.spinsText.text = '';
      this.visible = false;
    }
  }

  private setDisplay(visible: boolean): void {
    this.handleUpdate(setCurrentBonus().rounds, setCurrentBonus().currentRound, true);
    setTimeout(() => {
      this.visible = visible;
    }, 1);
  }
}
export default FreeSpinsCounter;
