/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { BgmSoundTypes, GraphQLErrorsType } from '../../global.d';
import {
  SetIsCountUp,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBrokenBuyFeature,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsContinueAutoSpinsAfterFeature,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsMeloFlag,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsOpenedMessageBanner,
  setIsPhoenix,
  setIsReplay,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setProgress,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setWinAmount,
} from '../../gql/cache';
import { placeBetGql } from '../../gql/mutation';
import { configGql, getAutoSpinsGql, getBetAmountGql, getUserGql, isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { EventTypes, SlotMachineState, eventManager } from '../../slotMachine/config';
import { GameMode } from '../../slotMachine/config/bonusInfo';
import { canPressSpin, showCurrency } from '../../utils';
import { getNonNullableValue, isFreeSpinsMode, saveReelPosition } from '../../utils/helper';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery(getBetAmountGql);
  const { id: slotId, lineSet } = useReactiveVar(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  const { data: userData } = useQuery(getUserGql);
  const progress = useReactiveVar(setProgress);
  const { data: dataSlotStopped } = useQuery(isStoppedGql);

  const gameMode = useReactiveVar(setGameMode);
  const balanceAmount = userData?.user.balance?.amount || 0;
  const [winFiveTimes, setGameHistory] = useState([false, false, false, false, false]);

  const { data: autoSpins } = useQuery(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) && !setCurrentBonus().isActive;

  const [fnGet, { client }] = useMutation(placeBetGql, {
    onError(error) {
      if (error.graphQLErrors.some((err) => err.extensions?.['type'] === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },

    async onCompleted({ placeBet }) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData!.user,
            balance: placeBet!.balance.placed,
          },
        },
      });
      saveReelPosition(getNonNullableValue(placeBet!.bet.result.reelPositions));
      SlotMachine.getInstance().setResult(placeBet);
      if (SlotMachine.getInstance().isStopped) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      const callBack = () => {
        const win = placeBet!.bet.result.winCoinAmount;
        const lastFiveSpins = [...winFiveTimes.slice(1), !!win];
        const thirdWinInRow = _.reduce(lastFiveSpins, (acc, item) => acc && item);
        setGameHistory(lastFiveSpins);

        if (placeBet!.bet.coinAmount * placeBet!.bet.lineSet!.coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
          setIsMeloFlag(true);
          BgmControl.fadeInMelo(3000);
        }
        client.writeQuery({
          query: getUserGql,
          data: {
            ...userData,
            user: {
              ...userData!.user,
              balance: placeBet!.balance.settled,
            },
          },
        });
      };
      SlotMachine.getInstance().setStopCallback(callBack.bind(this));
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      clearTimeout(timeout);

      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) {
          return;
        }

        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        resetPopupsStateToClosed();
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSet.id,
            },
          },
        });
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      clearTimeout(timeout);
      const type = setNextResult()?.bet.data.features.gameRoundStore.type;
      if (type === 'MULTIPLIER') {
        // eventManager.emit(
        //   EventTypes.SET_METER,
        //   setNextResult()?.bet.data.features.gameRoundStore.multiplier!,
        //   setNextResult()?.bet.data.features.gameRoundStore.heart!,
        // );
      } else if (type === 'FIXED') {
        eventManager.emit(
          EventTypes.SET_METER_MULTIPLIER,
          setNextResult()?.bet.data.features.gameRoundStore.multiplier!,
        );
      }
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSet.id,
            userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      BgmControl.playBgm(BgmSoundTypes.BB);
    };
    const buyFeatureSpin = () => {
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: setSlotConfig().lineSet.id,
            userBonusId: setCurrentBonusId(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
      if (AudioApi.isRestricted) {
        BgmControl.handleChangeRestriction(false);
      }
    };

    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.REGULAR && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT);
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isCountUp: SetIsCountUp(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isAutoPlay: false,
            isFadeOut: setIsFadeOut(),
            isBrokenBuyFeature: setBrokenBuyFeature(),
            isPhoenix: setIsPhoenix(),
            isReplay: setIsReplay(),
          })
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT);
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        } else if (SetIsCountUp()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        } else if (setIsReplay()) {
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winFiveTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winFiveTimes, (acc, item) => acc || item);
    if (play) {
      setIsMeloFlag(true);
      BgmControl.fadeInMelo(500);
    }

    if (!stop) {
      BgmControl.fadeOutMelo(3000);
    }
  }, [winFiveTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      const timeOut = config.autoplay.timeOut;

      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
