import * as PIXI from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { setGameMode, setNextResult } from '../../gql/cache';
import { getSpSymbolByBonusId, isFreeSpinsMode } from '../../utils/helper';
import { SLOT_HEIGHT } from '../config';

export class Slot extends PIXI.Sprite {
  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.y = SLOT_HEIGHT * id;
    this.anchor.set(0, 1);
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      let spSlot = undefined;

      if (isFreeSpinsMode(setGameMode())) {
        if (setNextResult()?.bet.data.features != null) {
          spSlot = getSpSymbolByBonusId(setNextResult()!.bet.data.features.gameRoundStore.bonusId);
        }
      }

      if (spSlot === this.textureSlotId) {
        this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
      } else {
        this.texture = PIXI.Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId]);
      }
    } else {
      this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
    }
  }

  public onSlotStopped(): void {
    // on slot stopped
  }
}
