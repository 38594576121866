import { SpineFile, SpineInterface } from '../../config/spine.generated';

interface ArrowParameter {
  CONTAINER_PORTRAIT_POS_X: number;
  CONTAINER_PORTRAIT_POS_Y: number;
  CONTAINER_LANDSCAPE_POS_X: number;
  CONTAINER_LANDSCAPE_POS_Y: number;
}

export const ArrowPram: ArrowParameter = {
  CONTAINER_PORTRAIT_POS_X: 1150,
  CONTAINER_PORTRAIT_POS_Y: -50,
  CONTAINER_LANDSCAPE_POS_X: 1150,
  CONTAINER_LANDSCAPE_POS_Y: -50,
};

export const ARROW_ANIMATION_NAME = 'stock';

export const ARROW_ANIMATIONS_STEPS: SpineInterface['stock']['animations'][] = [
  'stock0_idle',
  'stock1_idle',
  'stock2_idle',
  'stock3_idle',
  'stock4_idle',
  'stock5_idle',
  'stock6_idle',
];

export const ARROW_ANIMATIONS = {
  stock0_idle: 0,
  stock1_idle: 1,
  stock2_idle: 2,
  stock3_idle: 3,
  stock4_idle: 4,
  stock5_idle: 5,
  stock6_idle: 6,
};

export const HEART_ACQUISITION_NAME: SpineFile = 'acquisition';

export const SCATTER_HEART_STOCK_ANIMATIONS: SpineInterface['acquisition']['animations'][][] = [
  ['acquisition_r/acquisition_r1', 'acquisition_r/acquisition_r2', 'acquisition_r/acquisition_r3'],
  ['acquisition_r/acquisition_r4', 'acquisition_r/acquisition_r5', 'acquisition_r/acquisition_r6'],
  ['acquisition_r/acquisition_r7', 'acquisition_r/acquisition_r8', 'acquisition_r/acquisition_r9'],
  ['acquisition_r/acquisition_r10', 'acquisition_r/acquisition_r11', 'acquisition_r/acquisition_r12'],
  ['acquisition_r/acquisition_r13', 'acquisition_r/acquisition_r14', 'acquisition_r/acquisition_r15'],
];

export const EXPAND_HEART_STOCK_ANIMATIONS: SpineInterface['acquisition']['animations'][][] = [
  ['acquisition_m/acquisition_m1', 'acquisition_m/acquisition_m2', 'acquisition_m/acquisition_m3'],
  ['acquisition_m/acquisition_m1', 'acquisition_m/acquisition_m2', 'acquisition_m/acquisition_m3'],
  ['acquisition_m/acquisition_m1', 'acquisition_m/acquisition_m2', 'acquisition_m/acquisition_m3'],
  ['acquisition_m/acquisition_m1', 'acquisition_m/acquisition_m2', 'acquisition_m/acquisition_m3'],
  ['acquisition_m/acquisition_m4', 'acquisition_m/acquisition_m5', 'acquisition_m/acquisition_m6'],
];

export const HEART_STOCK_TO_METER_ANIMATIONS = 'acquisition_c1';

export const FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY = 1010;
export const SCATTER_HEART_STOCK_ANIMATION_DELAY = 600;

export const HEART_SHOT_BEFORE_DELAY = 500;
export const HEART_SHOT_ANIMATION_DELAY = 1010;

export const METER_ADD_DELAY = 410;

export const IN_EXPAND_DELAY = 500;
