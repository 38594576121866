import { isMobile } from 'mobile-device-detect';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { queryParams } from '../../utils/utils/utils';

import InfoSvg from './icons/InfoSvg';
import AutoplayOffSvg from './icons/autoplayOffSvg';
import AutoplayOnSvg from './icons/autoplayOnSvg';
import BetSvg from './icons/betSvg';
import BurgerSvg from './icons/burgerSvg';
import ConfirmSvg from './icons/confirmSvg';
import HistorySvg from './icons/historySvg';
import LobbySvg from './icons/lobbySvg';
import SkipSvg from './icons/skipSvg';
import SoundSvg from './icons/soundSvg';
import SpinSvg from './icons/spinSvg';
import StartSvg from './icons/startSvg';
import TurbospinOffSvg from './icons/turbospinOffSvg';
import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoButtonBehaviors' });

  return (
    <>
      <h1 className={styles['title']}>{t('Title')}</h1>
      <div className={styles['buttonBehaviors']}>
        <div className={`${styles['buttonBehaviors__item']} ${styles['start']}`}>
          <StartSvg /> {t('Start')}
        </div>
        <div className={`${styles['buttonBehaviors__item']} ${styles['spin']}`}>
          <SpinSvg /> {t('Spin')}
        </div>
        <div className={`${styles['buttonBehaviors__item']} ${styles['stop']}`}>
          <SkipSvg /> {t('Stop')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <AutoplayOnSvg /> {t('AutoPlay')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <AutoplayOffSvg /> {t('AutoPlayStop')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <BetSvg /> {t('BetSettings')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <TurbospinOffSvg /> {t('Turbo')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <BurgerSvg /> {t('Menu')}
        </div>
        <div className={styles['buttonBehaviors__item']}>
          <InfoSvg /> {t('Info')}
        </div>
      </div>

      <h1 className={styles['title']}>{t('BetSettingsTitle')}</h1>
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('BetSettingsText'),
        }}
      />

      <h1 className={styles['title']}>{t('AutoPlaySettingsTitle')}</h1>
      <p
        className={`${styles['p']} ${styles['center']}`}
        dangerouslySetInnerHTML={{
          __html: t('AutoPlaySettingsText'),
        }}
      />

      <div className={`${styles['row']} ${styles['center']}`}>
        <div className={styles['buttonBehaviors__item']}>
          <ConfirmSvg /> {t('AutoPlayStart')}
        </div>
      </div>

      <h1 className={styles['title']}>{t('MenuTitle')}</h1>
      <div className={styles['buttonBehaviors']}>
        <div className={styles['buttonBehaviors__item']}>
          <SoundSvg /> {t('Sound')}
        </div>
        {(lobbyBtnAvailable(queryParams).desktop || isMobile) && (
          <div className={styles['buttonBehaviors__item']}>
            <LobbySvg /> {t('MenuLobby')}
          </div>
        )}
        <div className={styles['buttonBehaviors__item']}>
          <HistorySvg /> {t('MenuHistory')}
        </div>
      </div>
      <p
        className={[styles['p'], styles['center']].join(' ')}
        dangerouslySetInnerHTML={{
          __html: t('Description'),
        }}
      />
    </>
  );
};

export default ButtonBehaviors;
