import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { setCoinValue } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { PAY_LINES } from '../../slotMachine/config';
import { countCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.symbolA)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return countCoins({ totalAmount: betAmount / PAY_LINES, coinValue: setCoinValue() }) * multiplier;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery(getUserGql);
  const { data: dataBet } = useQuery(getBetAmountGql);

  const { currency } = userData!.user.balance!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;
  const data2: IPaytableData[] = config.scatterTableData;
  const expandData: IPaytableData[] = config.ExpandTableData;

  return (
    <>
      <h1 className={styles['title']}>{t('infoTabPayTable')}</h1>
      <div className={`${styles['paytable-list']} ${styles['scatter']}`}>
        {data2.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles['img']}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['image__title']}>{t(`infoPayTable_${slug}`)}</div>
            </div>
            <div className={styles['content']}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles['multiplier']}>{i.pattern} </span>
                  {formatNumber({
                    currency: currency,
                    value: calcMultiplier(i.multiplier, betAmount, slug),
                    showCurrency: showCurrency(currency),
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <p
            className={`${styles['p']!} ${styles['center']!}`}
            dangerouslySetInnerHTML={{ __html: t('infoPayTable_symbolANormalInfo') }}
          />
        </div>
      </div>
      <div className={styles['paytable-list']}>
        {data.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles['img']}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
            </div>
            <div className={styles['content']}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles['multiplier']}>{i.pattern} </span>
                  {formatNumber({
                    currency: currency,
                    value: calcMultiplier(i.multiplier, betAmount, slug),
                    showCurrency: showCurrency(currency),
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <h1 className={styles['title']}>{t('SpecialExpandingSymbolsTitle')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <p
            className={`${styles['p']!} ${styles['center']!}`}
            dangerouslySetInnerHTML={{ __html: t('SpecialExpandingSymbolsText1') }}
          />
        </div>
      </div>

      <div className={`${styles['paytable-list']} ${styles['expand']}`}>
        {expandData.map(({ combos, slug }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles['img']}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['image__title']}>{t(`infoPayTable_${slug}`)}</div>
            </div>
            <div className={styles['content']}>
              {combos.map((i) => (
                <div key={i.pattern}>
                  <span className={styles['multiplier']}>{i.pattern} </span>
                  {formatNumber({
                    currency: currency,
                    value: calcMultiplier(i.multiplier, betAmount, slug),
                    showCurrency: showCurrency(currency),
                  })}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaytableComponent;
