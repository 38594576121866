import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import gameRules from '../../config/gameRules';
import { getBetsSetting } from '../../gql/fromFragment';
import { getUserGql } from '../../gql/query';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoGameRules' });
  const { data: userData } = useQuery(getUserGql);
  const { currency } = userData!.user.balance!;
  const betSettings = getBetsSetting();
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('Title')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {gameRules.map((v) => (
            <div className={styles['p']} key={v.key} dangerouslySetInnerHTML={{ __html: t(v.key) }} />
          ))}
        </div>
        <div className={styles['col']}>
          <div className={`${styles['p']} ${styles['center']}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('Min', {
                  minBet,
                }),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('Max', {
                  maxBet,
                }),
              }}
            />
          </div>
          <div className={`${styles['p']} ${styles['center']}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('RTP', {
                  rtp: '96.52%',
                }),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('BuyRtp', {
                  buyRtp: '96.69%',
                }),
              }}
            />
          </div>
          <div className={`${styles['p']} ${styles['center']}`}>
            <div>version: {version}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameRules;
