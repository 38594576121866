import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro1,
    txtKey: 'introTitle.page1.Bottom',
  },
  {
    img: ResourceTypes.intro2,
    txtKey: 'introTitle.page2.Bottom',
  },
  {
    img: ResourceTypes.intro3,
    txtKey: 'introTitle.page3.Bottom',
  },
];
