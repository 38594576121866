import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { setBrokenGame, setIsReplay, setNextResult, setUserLastBetResult } from '../../gql/cache';
import { getGameModeByBonusId } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { EventTypes, REELS_AMOUNT, REEL_WIDTH, SLOTS_CONTAINER_HEIGHT, eventManager } from '../config';
import { GameMode } from '../config/bonusInfo';

import {
  EXPAND_ANIMATIONS_NAME,
  EXPAND_ANIMATION_DELAY,
  EXPAND_ANIMATION_STEP,
  EXPAND_SYMBOL_ANIMATION_DELAY,
  EXPAND_SYMBOL_ANIMATION_END_DELAY,
  EXPAND_SYMBOL_ANIMATION_STEP,
  EXPAND_SYMBOL_IN_ANIMATION_DELAY,
} from './config';

class ExpandSymbol extends PIXI.Sprite {
  private expandAnimation: SpineAnimation[] = [];
  private expandSymbolAnimation: SpineAnimation[] = [];
  private isMax = false;
  private expandPosition: number[] = [];

  constructor() {
    super();

    this.pivot.set(0.5);
    this.x = REEL_WIDTH / 2;
    this.y = SLOTS_CONTAINER_HEIGHT / 2;

    this.animationInit();

    eventManager.addListener(EventTypes.START_EXPAND_SYMBOL, this.createExpandAnimations.bind(this));
    eventManager.addListener(EventTypes.END_EXPAND_SYMBOL, this.resetExpandAnimation.bind(this));
    eventManager.addListener(EventTypes.SCATTER_WIN_END, this.scatterWinEnd.bind(this));

    if (
      setBrokenGame() &&
      !(getGameModeByBonusId(setUserLastBetResult()!.data.features.gameRoundStore.bonusId) === GameMode.REGULAR)
    ) {
      if (
        setUserLastBetResult()!.data.features.gameRoundStore.multiplier ===
        setUserLastBetResult()!.data.features.gameRoundStore.maxMult
      ) {
        this.isMax = true;
      }
    }
  }

  private animationInit(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.expandAnimation[i] = new SpineAnimation({}, EXPAND_ANIMATIONS_NAME);
      this.expandSymbolAnimation[i] = new SpineAnimation({}, 'symbol_all');
    }
  }

  private startExpandSymbolAnimation(anim: SpineInterface['symbol_all']['animations'], i: number) {
    const spineAnim = new SpineAnimation({}, 'symbol_all');

    this.expandSymbolAnimation[i]! = spineAnim;
    this.expandSymbolAnimation[i]!.setAnimation(anim, true);
    this.expandSymbolAnimation[i]!.spine.x = i * REEL_WIDTH;
    this.addChild(this.expandSymbolAnimation[i]!.spine);
  }

  private startExpandAnimation(anim: SpineInterface['symbol_expand']['animations'], i: number) {
    const spineAnim = new SpineAnimation({}, EXPAND_ANIMATIONS_NAME);

    this.expandAnimation[i]! = spineAnim;
    this.expandAnimation[i]!.setAnimation(anim, false);
    this.expandAnimation[i]!.spine.x = i * REEL_WIDTH;
    this.addChild(this.expandAnimation[i]!.spine);
  }

  private createExpandAnimations(
    expandPosition: number[],
    expandHeightPosition: number[],
    symbolKind: number,
    isMax: boolean,
  ): void {
    const animationGroup = new AnimationGroup();
    this.expandPosition = expandPosition;
    this.isMax = isMax;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      if (expandPosition[i]) {
        animationGroup.addAnimation(this.createExpandSymbolAnimation(symbolKind, expandHeightPosition, i));
      }
    }
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.START_WIN_ANIMATION, setNextResult()!, false, false, true);
    });
    animationGroup.start();
  }

  private scatterWinEnd(): void {
    if (this.isMax) {
      eventManager.emit(EventTypes.END_EXPAND_SYMBOL);
    } else {
      eventManager.emit(EventTypes.EXPAND_HEART_STOCK, this.expandPosition);
    }
  }

  private createExpandSymbolAnimation(symbolKind: number, expandHeightPosition: number[], i: number): AnimationGroup {
    const animationGroup = new AnimationGroup();
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(EXPAND_SYMBOL_ANIMATION_DELAY);
    const inExpnad = Tween.createDelayAnimation(EXPAND_SYMBOL_IN_ANIMATION_DELAY);

    inExpnad.addOnStart(() => {
      this.startExpandSymbolAnimation(EXPAND_SYMBOL_ANIMATION_STEP[symbolKind]!, i);
    });
    animationGroup.addAnimation(this.createExpandAnimation(expandHeightPosition, i));
    animationChain.appendAnimation(delay);
    animationChain.appendAnimation(inExpnad);
    animationGroup.addAnimation(animationChain);
    return animationGroup;
  }

  private createExpandAnimation(expandHeightPosition: number[], i: number): AnimationChain {
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(EXPAND_ANIMATION_DELAY);

    delay.addOnStart(() => {
      AudioApi.play({ type: ISongs.Expand });
      if (expandHeightPosition[i]! < 5) {
        this.startExpandAnimation(EXPAND_ANIMATION_STEP[0]!, i);
      } else if (expandHeightPosition[i]! < 10) {
        this.startExpandAnimation(EXPAND_ANIMATION_STEP[1]!, i);
      } else {
        this.startExpandAnimation(EXPAND_ANIMATION_STEP[2]!, i);
      }
    });
    animationChain.appendAnimation(delay);
    return animationChain;
  }

  private resetExpandAnimation(): void {
    const animation = new AnimationGroup();

    for (let i = 0; i < REELS_AMOUNT; i++) {
      animation.addAnimation(
        this.getAlphaAnimation(this.expandSymbolAnimation[i]!.spine, EXPAND_SYMBOL_ANIMATION_END_DELAY, 1, 0),
      );
    }

    animation.addOnComplete(() => {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        this.removeChild(this.expandAnimation[i]!.spine);
        this.removeChild(this.expandSymbolAnimation[i]!.spine);
      }

      if (setIsReplay()) {
        eventManager.emit(EventTypes.SPIN_END);
      }
    });
    animation.start();
  }

  private getAlphaAnimation(object: Spine, duration: number, begin: number, target: number): Tween {
    const animation = new Tween({
      object: object,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }
}

export default ExpandSymbol;
