import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { SpineFile, SpineInterface } from '../../config/spine.generated';

import Animation from './animation';
import { IAnimation } from './d';

class SpineAnimation extends Animation {
  public spine: Spine;

  constructor(options: IAnimation, spineFile: SpineFile) {
    super(options);
    this.spine = new Spine(PIXI.Loader.shared.resources[spineFile]!.spineData!);
    this.spine.state.addListener({
      complete: this.onComplete.bind(this),
    });
  }

  public getSpine(): Spine {
    return this.spine!;
  }

  public setAnimation(animationName: SpineInterface[SpineFile]['animations'], loop: boolean): void {
    this.spine?.state.setAnimation(0, animationName, loop);
  }

  public addAnimation(animationName: SpineInterface[SpineFile]['animations'], loop: boolean, delay: number): void {
    this.spine?.state.addAnimation(0, animationName, loop, delay);
  }

  public setSkin(skinName: SpineInterface[SpineFile]['skins']): void {
    this.spine?.skeleton.setSkinByName(skinName);
  }

  public override start(): void {
    super.start();
  }
}
export default SpineAnimation;
