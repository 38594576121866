import { getFragmentData } from '../../gql/__generated__';
import { setSlotConfig, setUserLastBetResult } from '../../gql/cache';
import client from '../../gql/client';
import { historyNodeFragment } from '../../gql/fragment';
import { slotHistoryGql } from '../../gql/query';

export const useLastBet = () => {
  const getLastBet = async () => {
    const betsData = await client.query({
      query: slotHistoryGql,
      variables: {
        input: { last: 1, filter: { slotId: setSlotConfig().id } },
      },
      fetchPolicy: 'network-only',
    });
    if (betsData.data.bets?.edges[0]) {
      const node = getFragmentData(historyNodeFragment, betsData.data.bets?.edges[0].node);
      setUserLastBetResult(node);
    }
  };
  return { getLastBet };
};
