import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global.d';
import { setCurrentBonus, setIsMobile } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { EventTypes, MESSAGE_BANNER_MOBILE_SCALE, SLOT_WIDTH, eventManager } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_BANNER_FADE_IN_ALPHA,
  MESSAGE_BANNER_FADE_IN_X,
  MESSAGE_BANNER_POS_X,
  MESSAGE_BANNER_POS_Y,
  MESSAGE_TEXT_POS_X,
  MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y,
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
  TOTAL_WIN_Z_INDEX,
} from './config';
import { totalRoundAmountTextStyles, totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  // private closenessLevel: PIXI.Sprite[] = [];

  private charaCounter: number;

  private rect: PIXI.Graphics;

  private totalRoundText?: PIXI.Text;

  private bookAnimationFlash: SpineAnimation | undefined = undefined;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.sortableChildren = true;
    this.position.x = MESSAGE_BANNER_POS_X;
    this.position.y = MESSAGE_BANNER_POS_Y;
    this.charaCounter = 0;
    this.rect = new PIXI.Graphics();
    this.initBookAnimationFlash();
    if (setIsMobile()) {
      this.scale.set(MESSAGE_BANNER_MOBILE_SCALE);
    }
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.onSpaceKey.bind(this));
  }

  private onSpaceKey(): void {
    if (!this.delayDisplay) return;
    if (this.props.callback) {
      this.props.callback();
      this.props.callback = undefined;
    }
    if (!this.props.preventDefaultDestroy) this.handleDestroy();
  }

  public override init(): MessageWinBanner {
    super.init(ResourceTypes.totalWinBase);
    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.totalRoundText = this.initTotalRound(setCurrentBonus().totalRound);
    this.addChild(this.title, this.totalWin, this.totalRoundText);

    this.setZIndex();

    const animation = new AnimationGroup();
    animation.addAnimation(this.totalWinFadeIn(MESSAGE_BANNER_FADE_IN_X));
    animation.addAnimation(this.totalWinFadeInAlpha(MESSAGE_BANNER_FADE_IN_ALPHA));
    animation.addOnComplete(() => {
      this.totalWinDisplay();
    });
    animation.start();

    return this;
  }

  public totalWinDisplay(): void {
    this.rect.beginFill(0x000000);
    this.rect.drawRect(0, 0, this.banner!.width - 60, this.banner!.height - 60);
    this.rect.position.set(-this.banner!.width / 2, 0);
    this.rect.alpha = 0.5;

    const animation = new AnimationGroup();
    animation.addAnimation(
      this.totalWindDropShadow(
        250,
        TweenProperties.X,
        -(this.banner!.width - 60) / 2,
        -(this.banner!.width - 60) / 2 + 10,
      ),
    );
    animation.addAnimation(this.totalWindDropShadow(250, TweenProperties.Y, 30, 40));
    animation.start();

    this.addChild(this.rect);
  }

  private setZIndex(): void {
    this.rect.zIndex = TOTAL_WIN_Z_INDEX.rect;
    this.banner!.zIndex = TOTAL_WIN_Z_INDEX.banner;
    this.title!.zIndex = TOTAL_WIN_Z_INDEX.title;
    this.totalWin!.zIndex = TOTAL_WIN_Z_INDEX.totalWin;
    this.totalRoundText!.zIndex = TOTAL_WIN_Z_INDEX.totalRound;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.x = MESSAGE_TEXT_POS_X;
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    if (winTitle.width > 800) winTitle.width = 800;
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.resolution = 1;
    winTotal.anchor.set(0.5, 0);
    winTotal.x = MESSAGE_TEXT_POS_X;
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  protected initTotalRound(totalRound: number): PIXI.Text {
    const totalRoundText = new PIXI.Text(totalRound + ' ' + i18n.t<string>('freeSpins'), totalRoundAmountTextStyles);
    totalRoundText.resolution = 1;
    totalRoundText.anchor.set(0.5, 0);
    totalRoundText.x = MESSAGE_TEXT_POS_X;

    if (totalRoundText.width > 800) totalRoundText.width = 800;
    totalRoundText.y = MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y;
    return totalRoundText;
  }

  override updateTransform(): void {
    super.updateTransform();
  }

  private totalWinFadeIn(duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: -1200,
      target: (SLOT_WIDTH * 5) / 2,
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    return animation;
  }

  private totalWinFadeInAlpha(duration: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
    });
    return animation;
  }

  private totalWindDropShadow(
    duration: number,
    property: TweenProperties,
    BeginVal: number,
    targetVal: number,
  ): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: property,
      propertyBeginValue: BeginVal,
      target: targetVal,
    });
    return animation;
  }

  private initBookAnimationFlash(): void {
    this.bookAnimationFlash = new SpineAnimation({}, 'totalwin_flash');
    this.bookAnimationFlash.addOnStart(() => {
      this.addChild(this.bookAnimationFlash!.spine);
      this.bookAnimationFlash!.getSpine().position.set(0, 430);
      this.bookAnimationFlash!.getSpine().zIndex = TOTAL_WIN_Z_INDEX.flash;
    });
  }
}
