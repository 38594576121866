import { getFragmentData } from '../../gql/__generated__';
import {
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setIsBuyFeaturePopupOpened,
  setPrevReelsPosition,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { userBonusFragment } from '../../gql/fragment';
import { getBonuses, getUserBonuses, slotBetGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { GameMode } from '../../slotMachine/config/bonusInfo';
import { getGameModeByBonusId, getNonNullableValue } from '../../utils';

export const useBonuses = () => {
  const getPurchasableBonuses = async () => {
    const bonusData = await client.query({
      query: getBonuses,
      variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    setBonuses(bonusData.data.bonuses);
  };
  const checkBrokenGameMain = async () => {
    const activeUserBonusData = await client.query({
      query: getUserBonuses,
      variables: {
        input: { status: 'ACTIVE', slotId: setSlotConfig().id },
      },
      fetchPolicy: 'network-only',
    });

    const userBonuses = getFragmentData(userBonusFragment, getNonNullableValue(activeUserBonusData.data.userBonuses));

    if (userBonuses.length > 0) {
      if (GameMode.BUY_FEATURE === getGameModeByBonusId(userBonuses[0]!.bonus!.id)) {
        const specialBonus = userBonuses.find((ub) => ub.bonus!.type === 'SPECIAL_ROUND');
        setBrokenBuyFeature(true);
        setIsBuyFeaturePopupOpened(true);
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
        setCurrentBonusId(userBonuses[0]!.id);
        if (specialBonus) {
          setCurrentBonus({
            ...specialBonus,
            isActive: true,
            currentRound: specialBonus.roundsPlayed,
            totalRound: specialBonus.rounds + specialBonus.roundsPlayed,
          });
        }
      } else {
        const freeSpinsBonus = userBonuses.find((ub) => ub.bonus!.type === 'FREE_SPIN');

        if (freeSpinsBonus) {
          setBrokenGame(true);

          setCurrentBonus({
            ...freeSpinsBonus,
            isActive: true,
            currentRound: freeSpinsBonus.roundsPlayed,
            totalRound: freeSpinsBonus.rounds + freeSpinsBonus.roundsPlayed,
          });
          setCurrentFreeSpinsTotalWin(0);

          if (setCurrentBonus().id != '') {
            const res = await client.query({
              query: getUserBonuses,
              variables: { input: { id: setCurrentBonus().id } },
              fetchPolicy: 'network-only',
            });
            const userBonus = getFragmentData(userBonusFragment, getNonNullableValue(res.data.userBonuses![0]));
            const { betId } = userBonus;
            const bet = await client.query({
              query: slotBetGql,
              variables: { input: { id: betId! } },
              fetchPolicy: 'network-only',
            });

            setCurrentBonusId(setCurrentBonus().id);
            const lastBet = getNonNullableValue(setUserLastBetResult());
            setBrokenGameReelSet(lastBet.reelSetId);
            setPrevReelsPosition(lastBet.result.reelPositions);
            setCurrentBonus({
              ...setCurrentBonus(),
              rounds: bet.data.bet!.data.features.gameRoundStore.countFS,
              currentRound: bet.data.bet!.data.features.gameRoundStore.playedFS,
              totalRound: 0,
              bonus: {
                ...getNonNullableValue(setCurrentBonus().bonus),
                reelSetId: bet.data.bet!.reelSetId,
              },
            });
          }
        }
      }
    }
  };
  return { getPurchasableBonuses, checkBrokenGameMain };
};
