import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';
import { SpineFile, SpineInterface, spineFile } from './spine.generated';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.H]: ResourceTypes.symbolHBlur,
  [SlotId.I]: ResourceTypes.symbolIBlur,
  [SlotId.J]: ResourceTypes.symbolJBlur,
};

export const MAPPED_SP_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.I]: ResourceTypes.symbolI,
  [SlotId.J]: ResourceTypes.symbolJ,
};

export const MAPPED_EXPAND_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.WL]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolBSpecial,
  [SlotId.C]: ResourceTypes.symbolCSpecial,
  [SlotId.D]: ResourceTypes.symbolDSpecial,
  [SlotId.E]: ResourceTypes.symbolESpecial,
  [SlotId.F]: ResourceTypes.symbolFSpecial,
  [SlotId.G]: ResourceTypes.symbolGSpecial,
  [SlotId.H]: ResourceTypes.symbolHSpecial,
  [SlotId.I]: ResourceTypes.symbolISpecial,
  [SlotId.J]: ResourceTypes.symbolJSpecial,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: SpineFile;
    animation?: SpineInterface[SpineFile]['animations'];
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_announce',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_b_announce_sp',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_c_announce_sp',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_d_announce_sp',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_e_announce_sp',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_f_announce_sp',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_g_announce_sp',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_h_announce_sp',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_i_announce_sp',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_j_announce_sp',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: SpineFile;
    animation?: SpineInterface[SpineFile]['animations'];
    animationSp?: SpineInterface[SpineFile]['animations'];
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_win',
    animationSp: 'symbol_a_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_b_win',
    animationSp: 'symbol_b3_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_c_win',
    animationSp: 'symbol_c3_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_d_win',
    animationSp: 'symbol_d3_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_e_win',
    animationSp: 'symbol_e3_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_f_win',
    animationSp: 'symbol_f3_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_g_win',
    animationSp: 'symbol_g3_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_h_win',
    animationSp: 'symbol_h3_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_i_win',
    animationSp: 'symbol_i3_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_j_win',
    animationSp: 'symbol_j3_win',
  },
};

export const LOADER_TEXTURES: IAddOptions[] = Object.values(ResourceTypes).map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/spine/spine.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (_isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json'),
    },
    ...spineFile.map((name) => ({
      name,
      url: generateTexturePath('/animations', `${name}/${name}.json`),
    })),
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
