import { ResultOf } from '@graphql-typed-document-node/core';

import { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import { userBonusFragment } from './gql/fragment';
import { placeBetGql } from './gql/mutation';

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export enum ETypeSP {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER',
  REGULAR = 'REGULAR',
}

export type ISettledBet = ResultOf<typeof placeBetGql>['placeBet'];

export type BetRewardType = 'BetBonusReward' | 'BetCoinReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
};
export type UserBonus = ResultOf<typeof userBonusFragment> & {
  currentRound: number;
  totalRound: number;
  isActive: boolean;
};

enum ETypeSP {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER',
  REGULAR = 'REGULAR',
}

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}

export enum BgmSoundTypes {
  BASE = 'regular',
  BB_Start_Before = 'bbStartBefore',
  BB = 'bb',
}
