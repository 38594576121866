import _ from 'lodash';

import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BG_Music_Base_Loop = '034_01_BG_Music_Base_Loop',
  BG_Music_Melo_Loop = '034_02_BG_Music_Melo_Loop',
  FS_Select_Loop = '034_03_FS_Select_Loop',
  FS_Music_Loop = '034_04_FS_Music_Loop',
  TotalWinBanner = '034_05_TotalWinBanner',
  BigWin_Loop = '034_06_BigWin_Loop',
  BigWin_End = '034_07_BigWin_End',
  Win_Loop = '034_08_Win_Loop',
  Win_End = '034_09_Win_End',
  FS_Trigger = '034_10_FS_Trigger',
  BonusStop_1 = '034_11_BonusStop_1',
  BonusStop_2 = '034_12_BonusStop_2',
  BonusStop_3 = '034_13_BonusStop_3',
  BonusStop_4 = '034_14_BonusStop_4',
  BonusStop_5 = '034_15_BonusStop_5',
  LongSpin = '034_16_LongSpin',
  SpecialStop = '034_17_SpecialStop',
  Book = '034_18_Book',
  EffectMove = '034_19_EffectMove',
  MeterLit = '034_20_MeterLit',
  MultiUp_1 = '034_21_MultiUp_1',
  MultiUp_2 = '034_22_MultiUp_2',
  Expand = '034_23_Expand',
  FsReset = '034_24_FsReset',
  Wipe = '034_25_Wipe',
  Lightning = '034_26_Lightning',
  SE_Yocho_Phoenix = 'SE_Yocho_Phoenix',
  SFX_BuyFeature = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_Win_Big = 'SFX_Win_Big',
  SFX_Win_Epic = 'SFX_Win_Epic',
  SFX_Win_Great = 'SFX_Win_Great',
  SFX_Win_Mega = 'SFX_Win_Mega',
}

export enum ISongsVoice {}

export const audioSprite: TAudioSprite = {
  [ISongs.BG_Music_Base_Loop]: [0, 51692.33560090703, true],
  [ISongs.BG_Music_Melo_Loop]: [53000, 51692.33560090703, true],
  [ISongs.FS_Select_Loop]: [106000, 14769.251700680272, true],
  [ISongs.FS_Music_Loop]: [122000, 59076.961451247174, true],
  [ISongs.TotalWinBanner]: [183000, 6215.963718820859],
  [ISongs.BigWin_Loop]: [191000, 34532.562358276635, true],
  [ISongs.BigWin_End]: [227000, 4286.893424036293],
  [ISongs.Win_Loop]: [233000, 902.6303854875266, true],
  [ISongs.Win_End]: [235000, 918.4353741496523],
  [ISongs.FS_Trigger]: [237000, 879.841269841279],
  [ISongs.BonusStop_1]: [239000, 1771.678004535147],
  [ISongs.BonusStop_2]: [242000, 1771.678004535147],
  [ISongs.BonusStop_3]: [245000, 1771.678004535147],
  [ISongs.BonusStop_4]: [248000, 1771.678004535147],
  [ISongs.BonusStop_5]: [251000, 1771.678004535147],
  [ISongs.LongSpin]: [254000, 3980.430839002281],
  [ISongs.SpecialStop]: [259000, 1570.6802721088593],
  [ISongs.Book]: [262000, 582.8798185941082],
  [ISongs.EffectMove]: [264000, 1433.3333333333371],
  [ISongs.MeterLit]: [267000, 916.6666666666856],
  [ISongs.MultiUp_1]: [269000, 1322.4716553287976],
  [ISongs.MultiUp_2]: [272000, 1734.7619047619105],
  [ISongs.Expand]: [275000, 1879.387755102016],
  [ISongs.FsReset]: [278000, 403.8548752834572],
  [ISongs.Wipe]: [280000, 4355.600907029498],
  [ISongs.Lightning]: [286000, 901.7233560090858],
  [ISongs.SE_Yocho_Phoenix]: [288000, 4033.33333333336],
  [ISongs.SFX_BuyFeature]: [294000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [296000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [298000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [300000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [302000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [304000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [306000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [308000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [310000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [312000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [314000, 249.59183673468033],
  // [ISongs.SFX_UI_SpinStop]: [316000, 151.02040816327644],
  [ISongs.SFX_UI_SpinStop]: [315990, 151.02040816327644],
  // ↑ Lag at sound start causes sound crackling, which is avoided by delaying the sound by 0.1f.
  [ISongs.SFX_Win_Big]: [318000, 1622.3356009070358],
  [ISongs.SFX_Win_Epic]: [321000, 1862.2675736961583],
  [ISongs.SFX_Win_Great]: [324000, 2118.390022675726],
  [ISongs.SFX_Win_Mega]: [328000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BG_Music_Base_Loop]: 0.3,
  [ISongs.BG_Music_Melo_Loop]: 0.3,
  [ISongs.FS_Select_Loop]: 0.3,
  [ISongs.FS_Music_Loop]: 0.3,
  [ISongs.TotalWinBanner]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.FS_Trigger]: 0.3,
  [ISongs.BonusStop_1]: 0.3,
  [ISongs.BonusStop_2]: 0.3,
  [ISongs.BonusStop_3]: 0.3,
  [ISongs.BonusStop_4]: 0.3,
  [ISongs.BonusStop_5]: 0.3,
  [ISongs.LongSpin]: 0.5,
  [ISongs.SpecialStop]: 0.7,
  [ISongs.Book]: 0.6,
  [ISongs.EffectMove]: 0.5,
  [ISongs.MeterLit]: 0.6,
  [ISongs.MultiUp_1]: 0.3,
  [ISongs.MultiUp_2]: 0.8,
  [ISongs.Expand]: 0.5,
  [ISongs.FsReset]: 0.3,
  [ISongs.Wipe]: 0.3,
  [ISongs.Lightning]: 0.3,
  [ISongs.SE_Yocho_Phoenix]: 0.3,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.SFX_Win_Big]: 0.3,
  [ISongs.SFX_Win_Epic]: 0.3,
  [ISongs.SFX_Win_Great]: 0.3,
  [ISongs.SFX_Win_Mega]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
