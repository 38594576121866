export enum ResourceTypes {
  sparks = 'sparks',
  particle = 'particle',
  freespinFrame = 'freespinFrame',
  iconBase = 'iconBase',
  iconFrame = 'iconFrame',
  infoMeter = 'infoMeter',
  reelbase = 'reelbase',
  reelframe = 'reelframe',
  titlelogo = 'titlelogo',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolBSpecial = 'symbolBSpecial',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolCSpecial = 'symbolCSpecial',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolDSpecial = 'symbolDSpecial',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolESpecial = 'symbolESpecial',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolFSpecial = 'symbolFSpecial',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolGSpecial = 'symbolGSpecial',
  symbolH = 'symbolH',
  symbolHBlur = 'symbolHBlur',
  symbolHSpecial = 'symbolHSpecial',
  symbolI = 'symbolI',
  symbolIBlur = 'symbolIBlur',
  symbolISpecial = 'symbolISpecial',
  symbolJ = 'symbolJ',
  symbolJBlur = 'symbolJBlur',
  symbolJSpecial = 'symbolJSpecial',
  backdrop = 'backdrop',
  backgroundBasegame = 'backgroundBasegame',
  backgroundFreespins = 'backgroundFreespins',
  buttonCancel = 'buttonCancel',
  buttonOk = 'buttonOk',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  infoPaylines = 'infoPaylines',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  introBg = 'introBg',
  logo = 'logo',
  symbolLotteryWindow = 'symbolLotteryWindow',
  textLogo = 'textLogo',
  totalWinBase = 'totalWinBase',
}
