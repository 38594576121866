import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';

import { config } from '../../config';
import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setSlotConfig, setStressful } from '../../gql/cache';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  EventTypes,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
  eventManager,
} from '../../slotMachine/config';
import { getNonNullableValue } from '../helper';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    PIXI.Loader.shared.onError.once(() => {
      return reject();
    });
  });
};
export const loadPixiAssets = (assets: PIXI.IAddOptions[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    PIXI.Loader.shared.add(assets);
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.log(err);
      }
    }

    return success ? resolve() : reject();
  });
};
export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const getCssVariable = (cssVar: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number, minVal = 1): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(minVal, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const getTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number, minVal = 1): number => {
  text.scale.set(1, 1);
  text.updateText(true);
  return Math.min(minVal, Math.min(maxWidth / text.width, maxHeight / text.height));
};

export const countStage = (bet: number, win: number): WinStages => {
  const multiplier = win / bet;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const isPortrait = (width: number, height: number): boolean => {
  return width / height < 4 / 3;
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = getNonNullableValue(
    setSlotConfig().clientSettings!.coinValues.find((value) => value!.code === setCurrency())!.variants![0],
  );
  const coinAmount = findSubstituteCoinAmount(
    setCoinAmount(),
    getNonNullableValue(setSlotConfig().clientSettings!.coinAmounts.default),
  );
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const queryParams = new URLSearchParams(window.location.search);
