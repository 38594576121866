import { useQuery } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { getFragmentData } from '../../gql/__generated__';
import { HistoryNodeFragment } from '../../gql/__generated__/graphql';
import { setCurrency, setSlotConfig } from '../../gql/cache';
import { historyNodeFragment } from '../../gql/fragment';
import { slotHistoryGql } from '../../gql/query';
import i18n from '../../i18next';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { normalizeCoins, showCurrency } from '../../utils';
import { countCoins } from '../../utils/helper';

import styles from './history.module.scss';
import LeftSvg from './leftSvg';
import { ReplaySvg } from './replaySvg';
import RightSvg from './rightSvg';
import SkipLeftSvg from './skipLeftSvg';
import SkipRightSvg from './skipRightSvg';

type HistoryEdges = NonNullable<ResultOf<typeof slotHistoryGql>['bets']>['edges'];
type ISlotHistory = {
  gameId: string;
  date: string | number;
  bet: string;
  win: string;
  cursor: string;
  node: HistoryNodeFragment;
};

const formatHistory = (historyData: HistoryEdges): ISlotHistory[] => {
  const history = historyData.map((elem) => {
    const node = getFragmentData(historyNodeFragment, elem.node);
    const date = node.createdAt;
    const localDate = new Date(date).toLocaleString('en-US', { hour12: true });

    const baseCoinsAmount = node.coinAmount;
    const coinAmount = node.userBonus?.bonus ? node.userBonus.bonus.coinAmount * baseCoinsAmount : baseCoinsAmount;

    const coins = countCoins({
      coinValue: node.coinValue,
      coinAmount: coinAmount,
    });
    const bet = formatNumber({ currency: setCurrency(), value: coins, showCurrency: showCurrency(setCurrency()) });
    const win = formatNumber({
      currency: setCurrency(),
      value: normalizeCoins(node.result.winCoinAmount),
      showCurrency: showCurrency(setCurrency()),
    });

    return {
      gameId: node.id,
      date: localDate,
      win,
      bet,
      cursor: elem.cursor,
      node: node,
    };
  });

  return history.reverse();
};

const Replay = (result: HistoryNodeFragment, cursor: string) => {
  AudioApi.play({ type: ISongs.SFX_UI_General });
  eventManager.emit(EventTypes.REPLAY_SPIN, result, cursor);
};

const HistoryComponent: React.FC = () => {
  const { showBy } = config.historyTable;
  const { t } = useTranslation();
  const [pageAmount, setPageAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cursorLastRef = useRef('');
  const cursorFirstRef = useRef('');

  const { data: historyData, fetchMore } = useQuery(slotHistoryGql, {
    variables: {
      input: { last: showBy, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (historyData?.bets?.edges.length) {
      setPageAmount(Math.ceil(_.get(historyData, 'bets.pageInfo.count', 0) / showBy));
      cursorLastRef.current = historyData.bets.edges[historyData.bets.edges.length - 1]!.cursor;
      cursorFirstRef.current = historyData.bets.edges[0]!.cursor;
    }
  }, [historyData, showBy]);

  if (!historyData?.bets) {
    return (
      <div className={styles['history']}>
        <div className={styles['spinner']} />
      </div>
    );
  }

  const { pageInfo, edges } = historyData.bets;
  return (
    <div className={styles['history']}>
      <div className={styles['container']}>
        {formatHistory(edges).map((item, _index) => (
          <div key={item.gameId} className={styles['history-item']}>
            <div className={`${styles['history-item__col']} ${styles['date']} `}>
              {t('date')}
              <div className={styles['value']}>{item.date}</div>
            </div>
            <div className={styles['history-item__col']}>
              {t('bet')}
              <div className={styles['value']}>{item.bet}</div>
            </div>
            <div className={styles['history-item__col']}>
              {t('win')}
              <div className={styles['value']}>{item.win}</div>
            </div>
            <div className={`${styles['history-item__col']} ${styles['gameId']}`}>
              {t('gameId')}
              <div className={styles['value']}>{item.gameId}</div>
            </div>
            <div className={styles['history-item__col']}>
              {i18n.t('replay')}
              <Button
                className={`${styles['history-item__col']} ${styles['replay-button']}`}
                onClick={() => Replay(item.node, item.cursor)}
              >
                <ReplaySvg />
              </Button>
            </div>
          </div>
        ))}
      </div>
      {pageInfo.count > showBy && (
        <div className={styles['pagination']}>
          <Button
            type="button"
            disabled={currentPage === 1}
            className={styles['pagination__btn'] as string}
            key="first"
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(1);
              });
            }}
          >
            <SkipLeftSvg />
          </Button>
          <Button
            disabled={currentPage === 1}
            className={`${styles['pagination__btn']}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: showBy,
                    after: cursorLastRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.max(1, currentPage - 1));
              });
            }}
          >
            <LeftSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={`${styles['pagination__btn']}`}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    last: showBy,
                    before: cursorFirstRef.current,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(Math.min(pageAmount, currentPage + 1));
              });
            }}
          >
            <RightSvg />
          </Button>
          <Button
            disabled={currentPage === pageAmount}
            className={styles['pagination__btn']!}
            onClick={() => {
              fetchMore({
                variables: {
                  input: {
                    first: ((pageInfo.count - 1) % showBy) + 1,
                    filter: { slotId: setSlotConfig().id },
                  },
                },
              }).then(() => {
                setCurrentPage(pageAmount);
              });
            }}
          >
            <SkipRightSvg />
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(HistoryComponent);
