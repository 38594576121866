import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import SlotMachine from '../../slotMachine';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });
  const featuresFreeSpins: string[] = t('FreeSpinDesc', {
    returnObjects: true,
  });
  const featureFreeSpinsType1: string[] = t('FreeSpinType1', {
    returnObjects: true,
  });
  const featureFreeSpinsType2: string[] = t('FreeSpinType2', {
    returnObjects: true,
  });
  const featureFreeSpinsType3: string[] = t('FreeSpinType3', {
    returnObjects: true,
  });
  const featureFreeSpinsType4: string[] = t('FreeSpinType4', {
    returnObjects: true,
  });

  return (
    <>
      <section className={styles['feature']}>
        <h1 className={styles['title']}>{t('FreeSpinTitle')}</h1>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles['p']}>
              {featuresFreeSpins.map((v, i) => (
                <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
              ))}
            </div>
            <div className={styles['img']}>
              <img draggable="false" src={Resources.getSource(ResourceTypes.infoMeter)} />
            </div>
            <div className={`${styles['p']}  ${styles['center']}`}>{t('multiplayerMeterText')}</div>
          </div>
        </div>
      </section>

      <section className={styles['feature']}>
        <div className={styles['img']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolB)} />
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolG)} />
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolH)} />
        </div>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles['p']}>
              {featureFreeSpinsType1.map((v, i) => (
                <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={styles['feature']}>
        <div className={styles['img']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolC)} />
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolF)} />
        </div>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles['p']}>
              {featureFreeSpinsType2.map((v, i) => (
                <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={styles['feature']}>
        <div className={styles['img']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolD)} />
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolJ)} />
        </div>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles['p']}>
              {featureFreeSpinsType3.map((v, i) => (
                <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={styles['feature']}>
        <div className={styles['img']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolE)} />
          <img draggable="false" src={Resources.getSource(ResourceTypes.symbolI)} />
        </div>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles['p']}>
              {featureFreeSpinsType4.map((v, i) => (
                <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const infoBuyFeatureDesc: string[] = t('infoBuyFeatureDesc', {
    returnObjects: true,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoBuyFeatureTitle')}</h1>
      <div className={styles['image']}>
        <img draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <div className={styles['p']}>
            {infoBuyFeatureDesc.map((v, i) => (
              <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <FreeSpinFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
