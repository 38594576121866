import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../config';

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 120,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 2,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 8,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 100,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 2,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 8,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  align: 'left',
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};

export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};
// BuyFeature Button Text
export const FEATURE_BTN_TEXT_LANDSCAPE_FIT_TO_WIDTH = 120;
export const FEATURE_BTN_TEXT_PORTRAIT_FIT_TO_WIDTH = 250;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 0;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const FEATURE_BTN_TEXT_FONT_SIZE = 20;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: FEATURE_BTN_TEXT_FONT_SIZE,
  lineHeight: 0,
  lineJoin: 'round',
  fill: ['#ff80ff', '#ffffff'],
  stroke: '#000000',
  strokeThickness: 4,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: FEATURE_BTN_TEXT_FONT_SIZE + 1,
  lineHeight: 0,
  lineJoin: 'round',
  fill: ['#ffa0ff', '#ffffff'],
  stroke: '#60600000',
  strokeThickness: 4,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: FEATURE_BTN_TEXT_FONT_SIZE,
  lineHeight: 0,
  lineJoin: 'round',
  fill: ['#101010', '#383838'],
  stroke: '#000000',
  strokeThickness: 2,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

export const autoPlayCounterTextStyle = new PIXI.TextStyle({
  fontWeight: 'bold',
  fontFamily: Variables.FONT_FAMILY,
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
  lineJoin: 'round',
});

export const clockTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 26,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};
