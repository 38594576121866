import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoAutoPlay' });
  const descriptions: string[] = t('Description', { returnObjects: true });

  return (
    <div className={styles['autoplay']}>
      <h1 className={styles['title']}>{t('Title')}</h1>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {descriptions.map((desc, index) => (
            <p
              className={styles['p']}
              key={`autoplay-${index}`}
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AutoPlay;
