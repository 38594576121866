import { ResourceTypes } from '../../resources.d';
import { GAME_CONTAINER_WIDTH, SLOT_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = 10;

// WIN BANNER
export const MESSAGE_BANNER_POS_X = (SLOT_WIDTH * 5) / 2;
export const MESSAGE_BANNER_POS_Y = -50;
export const MESSAGE_TEXT_POS_X = 0;
export const MESSAGE_WIN_BANNER_TITLE_Y = 200;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 400;
export const MESSAGE_TOTAL_ROUND_BANNER_WIN_AMOUNT_Y = 610;

//delay
export const MESSAGE_BANNER_FLASH_DELAY = 1000;
export const MESSAGE_BANNER_LEVEL_UP_CHARACTER = 15;
export const MESSAGE_BANNER_LEVEL_UP_CLOSENESS = 1000;

export const MESSAGE_BANNER_FADE_IN_X = 500;
export const MESSAGE_BANNER_FADE_IN_ALPHA = 350;

export const CLOSENESS_LEVEL_X: number[] = [-200, -100, 0, 100, 200];
export const CLOSENESS_SCALE: number[] = [1, 0.7, 1, 0.7, 1];
export enum TOTAL_WIN_Z_INDEX {
  rect = 1,
  banner = 2,
  title = 3,
  totalWin = 4,
  totalRound = 5,
  closenessLevel = 6,
  flash = 7,
}

export interface resourceInfo {
  resource: ResourceTypes;
  isLeft: boolean;
}
