import { ResultOf } from '@graphql-typed-document-node/core';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { getAnimationSelectionTableLot } from '../anticipation';
import { AnimationType, baseGamePhoenix } from '../anticipation/table';
import { ISongs, SlotId, mappedAudioSprites } from '../config';
import { BgmSoundTypes, ISettledBet } from '../global.d';
import { FragmentType, getFragmentData } from '../gql/__generated__';
import { HistoryNodeFragment } from '../gql/__generated__/graphql';
import {
  SetIsCountUp,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCoinAmount,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setCurrentStage,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeaturePurchased,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsHistoryVisible,
  setIsMeloFlag,
  setIsPhoenix,
  setIsReplay,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpSymbolLotOpening,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setReplayEnd,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { betBonusRewardFragment, historyNodeFragment, userBonusFragment } from '../gql/fragment';
import { getUserBonuses, isStoppedGql, slotBetGql, slotHistoryGql } from '../gql/query';
import { getCoinAmount, getGameModeByBonusId, nextTick, normalizeCoins, showCurrency } from '../utils';
import {
  getExpandCount,
  getExpandSymbolCount,
  getExpandSymbolPosition,
  getGameModeByReelSetId,
  getNonNullableValue,
  getScatterCount,
  getScatterPosition,
  getSlotId,
  getSlotIdNumber,
  getSpSymbolByBonusId,
  getSpinResult5x3,
  isBaseGameMode,
  isBuyFeatureMode,
  isFreeSpinsMode,
} from '../utils/helper';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE,
  ANTICIPATION_SYMBOLS_ID,
  EventTypes,
  FREE_SPINS_FADE_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  REPLAY_END_DELAY,
  SlotMachineState,
  eventManager,
} from './config';
import { GameMode, reelSets } from './config/bonusInfo';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon } from './d';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import { getReplayResult } from './replay/getReplay';
import SafeArea from './safeArea/safeArea';
import SceneChange from './sceneChange/sceneChange';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import SlotsAnimationContainer from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: RecursiveNonNullable<ISlotConfig>;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public spinResult: Icon[];

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private multiplierOld = 0;

  private heartOld = 0;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotConfig as RecursiveNonNullable<ISlotConfig>,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public infoBuyFeatureIcon: PIXI.Container;

  public sceneChange: PIXI.Container;

  private freeSpinsBonusReelId: string | undefined;

  private constructor(
    application: PIXI.Application,
    slotConfig: RecursiveNonNullable<ISlotConfig>,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const lastBet = setUserLastBetResult();
    let startPosition = lastBet ? getNonNullableValue(lastBet.result.reelPositions) : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition);

    let reelSet = lastBet
      ? slotConfig.reels.find((reelSet) => reelSet.id === lastBet.reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === 'DEFAULT')!;

    setReelSetId(reelSet!.id);

    if (startPosition.length === 0) {
      startPosition = [0, 0, 0, 0, 0];
      reelSet = slotConfig.reels.find((reelSet) => reelSet.type === 'DEFAULT')!;
    }

    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult5x3({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });

    this.spinResult = spinResult;

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);
    this.application.stage.sortableChildren = true;
    this.bottom = new BottomContainer();
    this.application.stage.addChild(this.bottom);

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.sceneChange = new SceneChange();
    this.gameView = new GameView({
      winSlotsContainer: new SlotsAnimationContainer(),
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    this.initBuyFeature(slotConfig.lines, this.gameView);
    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();

    if (setBrokenBuyFeature()) {
      setIsSlotBusy(true);
      eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
      nextTick(() => {
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        if (this.state === SlotMachineState.IDLE) eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
      });
    }

    if (setBrokenGame()) {
      startPosition = setPrevReelsPosition();
      reelSet = slotConfig.reels.find((reelSet) => reelSet.id === setBrokenGameReelSet())!;
    }
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();

    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );

    if (setBrokenGame()) {
      this.onBrokenGame();
    }
    this.application.stage.addChild(this.sceneChange);

    this.phoenix = new Phoenix();
    this.application.stage.addChild(this.phoenix);
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(new BuyFeatureBtn(), new Backdrop(), new BuyFeaturePopup(lines), new BuyFeaturePopupConfirm());
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonus!.id);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().bonus!.reelSetId!);

    let currentRound = 0;

    if (!(getGameModeByBonusId(setUserLastBetResult()!.data.features.gameRoundStore.bonusId) === GameMode.REGULAR)) {
      this.multiplierOld = setUserLastBetResult()!.data.features.gameRoundStore.multiplier;
      this.heartOld = setUserLastBetResult()!.data.features.gameRoundStore.heart!;
      currentRound = setUserLastBetResult()!.data.features.gameRoundStore.playedFS;
      setCurrentBonus({ ...setCurrentBonus(), currentRound: currentRound });
    }

    if (setCurrentBonus().roundsPlayed === 0) {
      setIsSpSymbolLotOpening(true);
    }

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }

    this.nextResult = setNextResult();

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
    if (setCurrentBonus().roundsPlayed === 0) {
      setCurrentStage(1);
      eventManager.emit(EventTypes.SP_SYMBOL_LOT_START);
    } else {
      setCurrentStage(2);
      BgmControl.playBgm(BgmSoundTypes.BB);
      eventManager.emit(EventTypes.SET_SP_SYMBOL, getSpSymbolByBonusId(setCurrentBonus().bonus!.id));
      eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, true);
      this.setState(SlotMachineState.IDLE);
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      BgmControl.playBgmGameMode(setGameMode());
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.Win_Loop });
      }
    });

    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));

    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));

    eventManager.addListener(EventTypes.END_EXPAND_SYMBOL, this.expandEnd.bind(this));

    eventManager.addListener(EventTypes.START_EXPAND_SCENE, this.expandWin.bind(this));

    eventManager.addListener(EventTypes.REPLAY_SPIN, this.getPrevBet.bind(this));

    eventManager.addListener(EventTypes.REPLAY_END, this.endReplay.bind(this));
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.REGULAR]!,
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR) {
        setReelSetId(settings.reelSetId);
        const reelSet = this.slotConfig.reels.find((reels) => reels.id === settings.reelSetId);
        const spinResult = getSpinResult5x3({
          reelPositions: settings.reelPositions.slice(0, 5),
          reelSet: reelSet!,
          icons: this.slotConfig.icons,
        });
        if (!setIsReplay()) {
          setPrevReelsPosition(settings.reelPositions.slice(0, 5));
        }

        this.miniPayTableContainer.setSpinResult(spinResult);
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: this.slotConfig.reels.find((reels) => reels.id === settings.reelSetId)!,
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, false);

        setCurrentFreeSpinsTotalWin(0);
        this.isSpinInProgressCallback();

        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      }
    }
    if (settings.mode === GameMode.REGULAR) {
      eventManager.emit(EventTypes.SET_SP_SYMBOL, undefined);
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRound: 0,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
      this.freeSpinsBonusReelId = undefined;
      this.heartOld = 0;
      this.multiplierOld = 0;
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      setCurrentBonusId(setCurrentBonus().id);
      this.freeSpinsBonusReelId = SlotMachine.getInstance().getFreeSpinBonus()?.bonus!.reelSetId!;

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');

      setCurrentBonus({ ...bonus, currentRound: 0, totalRound: 0, isActive: true });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  private startFreeSpins(): void {}

  private async endFreeSpins(): Promise<void> {
    const res = await client.query({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = getNonNullableValue(getFragmentData(userBonusFragment, res.data.userBonuses![0]!));
    const bet = await client.query({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, reelSetId } = {
      reelPositions: getNonNullableValue(bet.data.bet!.result.reelPositions),
      reelSetId: bet.data.bet!.reelSetId,
    };

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    const callback = () => {
      eventManager.emit(EventTypes.SCENE_CHANGE_START, false, reelPositions, reelSetId);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({
            currency: setCurrency(),
            value: normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency: showCurrency(setCurrency()),
          }),
        );

        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());
      }, FREE_SPINS_FADE_DURATION);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    this.isSlotBusyCallback();

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('TotalWinTitle'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('TotalWinTitle'),
      });
    }

    setBrokenGame(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(_current: number): void {}

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange();
          let slotId = SlotId.J;
          if (this.nextResult.bet.data.features.gameRoundStore != undefined) {
            slotId = getSlotId(this.nextResult.bet.data.features.gameRoundStore.id);
          }
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            getNonNullableValue(this.nextResult.bet.result.reelPositions),
            getScatterCount(this.spinResult),
            getExpandCount(
              this.spinResult,
              this.nextResult.bet.data.features.gameRoundStore != undefined ? getSlotId(slotId) : SlotId.WL,
            ),
            getSlotId(slotId),
            this.getAnticipationReelId(this.spinResult),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        if (bonus.currentRound === 0 && bonus.totalRound > 0) {
          // AudioApi.play({ type: ISongs.FsReset });
        }
        bonus.currentRound += 1;
        bonus.totalRound += 1;

        eventManager.emit(
          EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
          setCurrentBonus().rounds,
          bonus.currentRound,
          false,
        );
        setCurrentBonus(bonus);
      }
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);

      spinAnimation.start();

      this.setState(SlotMachineState.SPIN);
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getPrevBet = async (replayBet: HistoryNodeFragment, cursor: string) => {
    if (setIsReplay()) return;
    setIsReplay(true);
    const prev = await client.query({
      query: slotHistoryGql,
      variables: {
        input: { last: 1, before: cursor, filter: { slotId: setSlotConfig().id } },
      },
      fetchPolicy: 'no-cache',
    });
    const prevBet = getFragmentData(historyNodeFragment, prev.data.bets?.edges[0]?.node) ?? undefined;
    this.replaySpin(replayBet, prevBet);
  };

  private replaySpin(replayBet: HistoryNodeFragment, prevBet: HistoryNodeFragment | undefined): void {
    this.isStopped = false;
    this.isReadyForStop = false;
    this.nextResult = null;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
    eventManager.emit(EventTypes.UI_VISIBLE, false);
    setIsHistoryVisible(false);
    this.multiplierOld = 1;

    const gameMode = getGameModeByReelSetId(replayBet.reelSetId);
    setGameMode(gameMode);

    if (isFreeSpinsMode(gameMode)) {
      setCurrentStage(2);
      const gameRoundStore = replayBet.data.features.gameRoundStore;

      eventManager.emit(
        EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
        gameRoundStore.countFS,
        gameRoundStore.playedFS,
        false,
      );

      setCurrentBonus({
        bonusId: gameRoundStore.bonusId,
        bonus: {
          coinAmount: replayBet.coinAmount,
          id: gameRoundStore.bonusId,
          reelSetId: replayBet.reelSetId,
          type: 'FREE_SPIN',
        },
        data: {
          count: 0,
          creditMultiplier: 1,
          initialRoundId: '',
          storeCoinAmount: true,
          storeCoinValue: true,
        },
        coinAmount: replayBet.coinAmount,
        coinValue: 100,
        totalWinAmount: replayBet.coinAmount,
        betId: replayBet.id,
        id: gameRoundStore.id,
        status: 'ACTIVE',
        isActive: true,
        rounds: gameRoundStore.countFS,
        roundsPlayed: gameRoundStore.playedFS,
        currentRound: prevBet ? prevBet.data.features.gameRoundStore.playedFS + 1 : 1,
        totalRound: prevBet ? prevBet.data.features.gameRoundStore.playedFS + 1 : 1,
      });

      this.heartOld = prevBet ? prevBet.data.features.gameRoundStore.heart! : 0;
      this.multiplierOld = prevBet ? prevBet.data.features.gameRoundStore.multiplier : 1;
      this.freeSpinsBonusReelId = replayBet.reelSetId;
      eventManager.emit(EventTypes.SET_SP_SYMBOL, getSpSymbolByBonusId(gameRoundStore.bonusId));

      eventManager.emit(
        EventTypes.REPLAY_METER,
        getSpSymbolByBonusId(gameRoundStore.bonusId),
        this.multiplierOld,
        this.heartOld,
        gameRoundStore.maxMult!,
      );
    }

    const replayResult = getReplayResult(replayBet, this.multiplierOld);

    eventManager.emit(
      EventTypes.START_FADE,
      () => {
        eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, true);
        eventManager.emit(EventTypes.START_SPIN_ANIMATION);
        this.skipAnimations();
        eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
        const spinAnimation = this.getSpinAnimation(false);

        if (isFreeSpinsMode(gameMode)) {
          eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
          eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, true);
        }
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
        spinAnimation.start();

        this.setState(SlotMachineState.SPIN);
      },
      () => {
        this.setResult(replayResult);
      },
    );
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinsMode(setGameMode())) {
              // this.updateFreeSpinsAmount(setCurrentBonus().currentRound);
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            let slotId = SlotId.J;
            if (this.nextResult.bet.data.features.gameRoundStore != undefined) {
              slotId = getSlotId(this.nextResult.bet.data.features.gameRoundStore.id);
            }
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getNonNullableValue(this.nextResult.bet.result.reelPositions),
              getScatterCount(this.spinResult),
              getExpandCount(this.spinResult, slotId),
              slotId,
              this.getAnticipationReelId(this.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public getFreeSpinBonus(): ResultOf<typeof userBonusFragment> | undefined | null {
    type RewardType = NonNullable<NonNullable<ISettledBet>['rewards']>[number];
    type BetBonusRewardType = FragmentType<typeof betBonusRewardFragment> & { __typename: 'BetBonusReward' };

    const isBonusReward = (reward: RewardType): reward is BetBonusRewardType => reward?.__typename === 'BetBonusReward';
    const reward = this.nextResult?.rewards
      ?.filter(isBonusReward)
      .map((rf) => getFragmentData(betBonusRewardFragment, rf))
      .find((r) => getFragmentData(userBonusFragment, r.userBonus)?.bonus?.type === 'FREE_SPIN');

    return getFragmentData(userBonusFragment, reward?.userBonus);
  }

  private onCountUpEnd(isExpand: boolean, value?: number): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();

    if (freeSpinsBonus && isFreeSpinsMode(mode)) {
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
        currentRound: 0,
        totalRound: setCurrentBonus().totalRound,
      });
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (getCoinAmount(this.nextResult!, true, false) > 0 && !isExpand) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, value!);
      }
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      this.scatterHeart();
      //this.setState(SlotMachineState.IDLE);
    } else if (freeSpinsBonus && !isFreeSpinsMode(mode)) {
      if (!setIsReplay()) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setIsFreeSpinsWin(true);
      }

      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
        currentRound: 0,
        totalRound: 0,
      });

      const delay = Tween.createDelayAnimation(500);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.SCENE_CHANGE_START, true);
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      });
      delay.start();
    } else if (!freeSpinsBonus && isFreeSpinsMode(mode)) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.REGULAR,
        reelPositions: [0, 0, 0, 0, 0],
        reelSetId: this.nextResult!.bet.reelSetId,
      });
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private scatterHeart(): void {
    let scatterCount = getScatterCount(this.spinResult);
    let scatterPosition = getScatterPosition(this.spinResult);
    const spSlotId = getSpSymbolByBonusId(this.nextResult!.bet.data.features.gameRoundStore.bonusId);

    eventManager.emit(
      EventTypes.SET_METER,
      setNextResult()?.bet.data.features.gameRoundStore.multiplier!,
      setNextResult()?.bet.data.features.gameRoundStore.heart!,
    );

    if (
      (this.multiplierOld === setNextResult()?.bet.data.features.gameRoundStore.multiplier! &&
        this.heartOld === setNextResult()?.bet.data.features.gameRoundStore.heart!) ||
      spSlotId === SlotId.C ||
      spSlotId === SlotId.E ||
      spSlotId === SlotId.F ||
      spSlotId === SlotId.I
    ) {
      scatterCount = [0, 0, 0, 0, 0];
      scatterPosition = [];
    }
    eventManager.emit(EventTypes.SCATTER_HEART_STOCK, scatterCount, scatterPosition);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.spinResult, false);
  }

  private expandWin(): void {
    const spSlotId = getSpSymbolByBonusId(this.nextResult!.bet.data.features.gameRoundStore.bonusId);
    const expandSymbolCount = getExpandSymbolCount(this.spinResult, spSlotId);
    const expandSymbolPosition = getExpandSymbolPosition(this.spinResult, spSlotId);
    let scatterCount = getScatterCount(this.spinResult);
    let isMax = false;

    if (
      (this.multiplierOld === setNextResult()?.bet.data.features.gameRoundStore.multiplier! &&
        this.heartOld === setNextResult()?.bet.data.features.gameRoundStore.heart!) ||
      spSlotId === SlotId.C ||
      spSlotId === SlotId.E ||
      spSlotId === SlotId.F ||
      spSlotId === SlotId.I
    ) {
      scatterCount = [0, 0, 0, 0, 0];
      isMax = true;
    }

    this.multiplierOld = setNextResult()?.bet.data.features.gameRoundStore.multiplier!;
    this.heartOld = setNextResult()?.bet.data.features.gameRoundStore.heart!;

    if (spSlotId! <= SlotId.E) {
      if (Math.max(...expandSymbolCount) >= 2) {
        eventManager.emit(
          EventTypes.START_EXPAND_SYMBOL,
          expandSymbolCount,
          expandSymbolPosition,
          getSlotIdNumber(spSlotId!),
          isMax,
        );
      } else if (Math.max(...scatterCount) >= 3) {
        eventManager.emit(EventTypes.HEART_SHOT);
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    } else if (spSlotId! > SlotId.E) {
      if (Math.max(...expandSymbolCount) >= 3) {
        eventManager.emit(
          EventTypes.START_EXPAND_SYMBOL,
          expandSymbolCount,
          expandSymbolPosition,
          getSlotIdNumber(spSlotId!),
          isMax,
        );
      } else if (Math.max(...scatterCount) >= 3) {
        eventManager.emit(EventTypes.HEART_SHOT);
      } else {
        this.setState(SlotMachineState.IDLE);
      }
    }
  }

  private expandEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== this.nextResult!.bet.reelSetId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.id === this.nextResult!.bet.reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      if (!setIsReplay()) {
        setReelSetId(this.nextResult!.bet.reelSetId);
      }
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
    setIsBuyFeaturePurchased(false);
  }

  private getAnticipationReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        if (spinResult[j + REELS_AMOUNT * 0]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 1]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 2]!.id === symbolId) currentCount += 1;

        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });

    if (setNextResult()!.bet.result.winCoinAmount / setBetAmount() >= 500 && !setIsReplay()) {
      const AnimationPtn = getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix);
      if (AnimationPtn === AnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      }
    }

    return minReelId;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  private endReplay(): void {
    if (setIsReplay() && !SetIsCountUp() && !setIsFadeOut()) {
      setGameMode(GameMode.REGULAR);
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      this.freeSpinsBonusReelId = undefined;
      eventManager.emit(EventTypes.SET_SP_SYMBOL, undefined);
      eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
      eventManager.emit(
        EventTypes.START_FADE,
        () => {
          eventManager.emit(EventTypes.SET_REPLAY_TEXT_VISIBILITY, false);
          eventManager.emit(EventTypes.UI_VISIBLE, true);
          eventManager.emit(EventTypes.CHANGE_REEL_SET, {
            reelSet: this.slotConfig.reels.find((reels) => reels.id === setReelSetId())!,
            reelPositions: setPrevReelsPosition(),
          });
          const spinResult = getSpinResult5x3({
            reelPositions: setPrevReelsPosition(),
            reelSet: this.slotConfig.reels.find((reels) => reels.id === setReelSetId())!,
            icons: this.slotConfig.icons,
          });
          eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
          eventManager.emit(EventTypes.SPIN_END);
          this.miniPayTableContainer.setSpinResult(spinResult);
          setCurrentStage(0);
          BgmControl.playBgm(BgmSoundTypes.BASE);
          if (setIsMeloFlag()) {
            BgmControl.fadeInMelo(3000);
          }
          eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
          setIsHistoryVisible(true);
        },
        () => {
          setIsReplay(false);
          eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, true);
        },
      );

      if (setCurrentBonus().isActive) {
        setCurrentBonus({ ...setCurrentBonus(), isActive: false });
        eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, false);
      }
    }
  }

  public setResult(result: ISettledBet): void {
    const reelPositions = getNonNullableValue(result!.bet.result.reelPositions.slice(0, REELS_AMOUNT));
    const spinResult = getSpinResult5x3({
      reelPositions: reelPositions,
      reelSet: this.slotConfig.reels.find((reelSet) => reelSet.id === result?.bet.reelSet?.id)!,
      icons: this.slotConfig.icons,
    });
    this.spinResult = spinResult;

    if (!setIsReplay()) {
      setPrevReelsPosition(reelPositions);
    }
    this.nextResult = result;
    setNextResult(result);

    if ((isBaseGameMode(setGameMode()) || isBuyFeatureMode(setGameMode())) && !setIsReplay()) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult!.balance.placed);
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    if (this.nextResult === null) {
      return false;
    }
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature() || setIsFadeOut(),
    );

    if (state === SlotMachineState.IDLE) {
      if (setIsReplay()) {
        const delay = Tween.createDelayAnimation(REPLAY_END_DELAY);
        delay.addOnComplete(() => {
          setReplayEnd(true);
        });
        delay.start();
      }
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinsMode(setGameMode()) && !setIsReplay()) {
        if (
          setCurrentBonus().isActive &&
          this.nextResult?.bet.data.features.gameRoundStore.countFS! <=
            this.nextResult?.bet.data.features.gameRoundStore.playedFS!
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => {
              const bonus = setCurrentBonus();
              if (bonus.currentRound === 0 && bonus.totalRound > 0) {
                AudioApi.play({ type: ISongs.FsReset });

                eventManager.emit(
                  EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
                  setCurrentBonus().rounds,
                  bonus.currentRound,
                  false,
                );
                const delay = Tween.createDelayAnimation(1000);
                delay.addOnComplete(() => {
                  eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
                });
                delay.start();
              } else {
                eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
              }
            },
            setCurrentBonus().totalRound === 0 ? 0 : 500,
          );
        }
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus() && (isBaseGameMode(setGameMode()) || isBuyFeatureMode(setGameMode()))) {
        const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.FS_Trigger].duration);
        jingleDelay.addOnStart(() => {
          AudioApi.play({ type: ISongs.FS_Trigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      setIsPhoenix(false);
      eventManager.emit(EventTypes.SPIN_END);
      if (this.hasWin()) {
        if (Math.max(...getScatterCount(this.spinResult)) >= 3) {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, true, false);
        } else {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, false, false);
        }
      } else {
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        }
        this.onCountUpEnd(true);
      }
    }
  }
}

export default SlotMachine;
