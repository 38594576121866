import type React from 'react';

export const ReplaySvg = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="currentColor"
    >
      <path d="m72,7.27c-3.65,0-6.61,2.96-6.61,6.61s2.96,6.61,6.61,6.61c28.12,0,51,22.88,51,51s-22.88,51-51,51-51-22.88-51-51c0-9.78,2.71-19.08,7.85-27.2,1.09,2.2,3.35,3.68,5.92,3.68.15,0,.3,0,.45-.02,3.64-.25,6.4-3.4,6.15-7.04l-.95-14.03c-.25-3.64-3.39-6.38-7.04-6.15l-14.03.93c-3.64.24-6.4,3.39-6.16,7.04.22,3.31,2.84,5.88,6.06,6.14-7.43,10.7-11.49,23.56-11.49,36.64,0,35.41,28.81,64.23,64.23,64.23s64.23-28.81,64.23-64.23S107.41,7.27,72,7.27Z" />
      <path d="m75.75,70.53l-11.93,10.87c-2.7,2.46-2.89,6.64-.43,9.34,1.3,1.43,3.09,2.16,4.89,2.16,1.59,0,3.18-.57,4.45-1.72l17.29-15.76c1.37-1.25,2.16-3.03,2.16-4.89s-.78-3.63-2.16-4.89l-17.29-15.77c-2.7-2.46-6.88-2.27-9.34.43-2.46,2.7-2.27,6.88.43,9.34l11.93,10.88Z" />
    </svg>
  );
};
