import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { ParticleEmitterContainer } from '../components/particle';
import { EventTypes, eventManager } from '../config';
import { GameMode } from '../config/bonusInfo';

import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from './config';
import { glitterConfig } from './glitterConfig';

class Background extends ViewContainer {
  private bgTextures: Record<BgSkin, PIXI.Texture> = {
    default: PIXI.Texture.from(ResourceTypes.backgroundBasegame),
    feature: PIXI.Texture.from(ResourceTypes.backgroundFreespins),
  };

  private bgSprite = new PIXI.Sprite();

  private currentSkin?: BgSkin;

  private glitterEffect: ParticleEmitterContainer;

  constructor(skin: BgSkin = 'default') {
    super();

    this.bgSprite.anchor.set(0.5, 0.5);
    this.addChild(this.bgSprite);

    this.glitterEffect = new ParticleEmitterContainer(glitterConfig);
    this.addChild(this.glitterEffect);

    this.setSkin(skin);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;
    this.bgSprite.texture = this.bgTextures[this.currentSkin];
    if (skinName === 'default') {
      this.glitterEffect.stop(true);
    } else if (skinName === 'feature') {
      this.glitterEffect.start();
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (settings.mode === GameMode.REGULAR || settings.mode === GameMode.BUY_FEATURE) {
      this.setSkin('default');
    } else {
      this.setSkin('feature');
    }
  }

  private resize(width: number, height: number): void {
    this.bgSprite.x = width / 2;
    this.bgSprite.y = height / 2;
    this.glitterEffect.position.set(width / 2, height / 2);

    const bgAspectRatio = BACKGROUND_SIZE_WIDTH / BACKGROUND_SIZE_HEIGHT;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.bgSprite.scale.set(height / BACKGROUND_SIZE_HEIGHT);
    } else {
      this.bgSprite.scale.set(width / BACKGROUND_SIZE_WIDTH);
    }

    this.glitterEffect.scale.set(this.bgSprite.scale.x);
  }

  private resizeGameContainer(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    _scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    this.setScale();
  }

  private setScale(): void {}
}

export default Background;
