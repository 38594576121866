import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import AnimationChain from '../animations/animationChain';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { EventTypes, REELS_AMOUNT, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

import {
  EXPAND_HEART_STOCK_ANIMATIONS,
  FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY,
  HEART_ACQUISITION_NAME,
  HEART_SHOT_ANIMATION_DELAY,
  HEART_SHOT_BEFORE_DELAY,
  HEART_STOCK_TO_METER_ANIMATIONS,
  IN_EXPAND_DELAY,
  METER_ADD_DELAY,
  SCATTER_HEART_STOCK_ANIMATIONS,
  SCATTER_HEART_STOCK_ANIMATION_DELAY,
} from './config';

class Heart extends PIXI.Sprite {
  private heartAnimation: SpineAnimation | null = null;

  private heartCounter = 0;

  constructor() {
    super();

    this.pivot.set(0.5);
    this.x = SLOTS_CONTAINER_WIDTH / 2;
    this.y = SLOTS_CONTAINER_HEIGHT / 2;
    this.animationInit();
    eventManager.addListener(EventTypes.SCATTER_HEART_STOCK, this.setScatterAnimation.bind(this));
    eventManager.addListener(EventTypes.EXPAND_HEART_STOCK, this.setExpandAnimation.bind(this));
    eventManager.addListener(EventTypes.SET_INIT_SCATTER_METER, this.setInitScatterAnimation.bind(this));
    eventManager.addListener(EventTypes.HEART_SHOT, this.createHeartShot.bind(this));
  }

  private animationInit(): void {
    this.heartAnimation = new SpineAnimation({}, HEART_ACQUISITION_NAME);
  }

  private startAnimation(anim: SpineInterface['acquisition']['animations']) {
    this.addChild(this.heartAnimation!.spine);
    this.heartAnimation!.setAnimation(anim, false);
  }

  private createMeterHeartStockAnimation(
    delayTime: number,
    anim: SpineInterface['acquisition']['animations'],
  ): AnimationChain {
    const animationChain = new AnimationChain();

    const delay = Tween.createDelayAnimation(delayTime + METER_ADD_DELAY);
    delay.addOnStart(() => {
      AudioApi.play({ type: ISongs.Lightning });
      this.heartCounter++;
      this.startAnimation(anim);

      const meter = Tween.createDelayAnimation(delayTime - 600);
      meter.addOnComplete(() => {
        eventManager.emit(EventTypes.METER_ADD_HEART, true, [0], [0]);
        eventManager.emit(EventTypes.START_METER_ADD_COUNT, true);
      });
      meter.start();
    });

    animationChain.appendAnimation(delay);
    return animationChain;
  }

  private setInitScatterAnimation(scatterCount: number[], scatterPosition: number[]): void {
    const animationChain = new AnimationChain();
    const scatterMaxCount = Math.max(...scatterCount);
    scatterPosition.sort((a, b) => (a % REELS_AMOUNT) - (b % REELS_AMOUNT));

    if (scatterMaxCount === 5) {
      for (let i = 3; i < REELS_AMOUNT; i++) {
        if (scatterCount[i]) {
          const acquisition = this.createMeterHeartStockAnimation(
            FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY,
            EXPAND_HEART_STOCK_ANIMATIONS[i]![this.getSCPosition(scatterPosition[i]!)]!,
          );
          animationChain.appendAnimation(acquisition);
        }
      }
    } else if (scatterMaxCount === 4) {
      if (scatterCount[4]) {
        const acquisition = this.createMeterHeartStockAnimation(
          FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY,
          EXPAND_HEART_STOCK_ANIMATIONS[4]![this.getSCPosition(scatterPosition[4]!)]!,
        );
        animationChain.appendAnimation(acquisition);
      } else {
        animationChain.appendAnimation(
          this.createMeterHeartStockAnimation(
            FREE_SPIN_IN_SCATTER_HEART_STOCK_ANIMATION_DELAY,
            EXPAND_HEART_STOCK_ANIMATIONS[3]![this.getSCPosition(scatterPosition[3]!)]!,
          ),
        );
      }
    }

    animationChain.start();
    animationChain.addOnComplete(() => {
      this.heartCounter = 0;
      eventManager.emit(EventTypes.END_EXPAND_SYMBOL);
    });
  }

  private createArrowHeartStockAnimation(
    delayTime: number,
    anim: SpineInterface['acquisition']['animations'],
  ): AnimationChain {
    const animationChain = new AnimationChain();

    const delay = Tween.createDelayAnimation(delayTime);
    delay.addOnStart(() => {
      if (anim != HEART_STOCK_TO_METER_ANIMATIONS) {
        AudioApi.stop({ type: ISongs.Lightning });
        AudioApi.play({ type: ISongs.Lightning });
      }
      this.heartCounter++;
      this.startAnimation(anim);

      if (anim != HEART_STOCK_TO_METER_ANIMATIONS!) {
        const heartAddDelay = Tween.createDelayAnimation(delayTime - 300);
        heartAddDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.HEART_SET_ADD);
          AudioApi.stop({ type: ISongs.MeterLit });
          AudioApi.play({ type: ISongs.MeterLit });
        });
        heartAddDelay.start();
      }
    });

    animationChain.appendAnimation(delay);
    return animationChain;
  }

  private setScatterAnimation(scatterCount: number[], scatterPosition: number[]): void {
    const animationChain = new AnimationChain();
    scatterPosition.sort((a, b) => (a % REELS_AMOUNT) - (b % REELS_AMOUNT));

    if (Math.max(...scatterCount) >= 3) {
      scatterPosition.forEach((position) => {
        animationChain.appendAnimation(
          this.createArrowHeartStockAnimation(
            SCATTER_HEART_STOCK_ANIMATION_DELAY,
            SCATTER_HEART_STOCK_ANIMATIONS[position % REELS_AMOUNT]![Math.floor(position / REELS_AMOUNT)]!,
          ),
        );
      });
      const delay = Tween.createDelayAnimation(500);
      animationChain.appendAnimation(delay);
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.METER_ADD_HEART, false, scatterCount, [0]);
        eventManager.emit(EventTypes.START_EXPAND_SCENE);
      });
    } else {
      animationChain.appendAnimation(Tween.createDelayAnimation(IN_EXPAND_DELAY));
      animationChain.addOnComplete(() => {
        eventManager.emit(EventTypes.START_EXPAND_SCENE);
      });
    }

    animationChain.start();
  }

  private setExpandAnimation(expandCount: number[]): void {
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(HEART_SHOT_BEFORE_DELAY);
    delay.addOnStart(() => {
      eventManager.emit(EventTypes.HEART_SET_ADD);
    });
    if (Math.max(...expandCount)) {
      AudioApi.play({ type: ISongs.MeterLit });
      animationChain.appendAnimation(delay);
    }
    animationChain.start();
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.METER_ADD_HEART, false, [0], expandCount);
      eventManager.emit(EventTypes.HEART_SHOT);
    });
  }

  private createHeartShot() {
    const animationChain = new AnimationChain();
    const delay = Tween.createDelayAnimation(HEART_SHOT_ANIMATION_DELAY - 600);
    delay.addOnComplete(() => {
      this.heartCounter = 0;
      eventManager.emit(EventTypes.START_METER_ADD_COUNT, false);
    });
    animationChain.appendAnimation(
      this.createArrowHeartStockAnimation(HEART_SHOT_ANIMATION_DELAY, HEART_STOCK_TO_METER_ANIMATIONS),
    );
    animationChain.addOnStart(() => {
      delay.start();
    });
    animationChain.start();
  }

  private getSCPosition(scPos: number): number {
    if (scPos < 5) {
      return 0;
    } else if (scPos < 10) {
      return 1;
    } else {
      return 2;
    }
  }
}

export default Heart;
