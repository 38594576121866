import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BgSkin } from '../background/config';
import ViewContainer from '../components/container';
import { EventTypes, eventManager } from '../config';
import { GameMode } from '../config/bonusInfo';
import { MeterKind } from '../meter/config';

import { ARROW_ANIMATIONS, ARROW_ANIMATIONS_STEPS, ARROW_ANIMATION_NAME, ArrowPram } from './config';

class Arrow extends ViewContainer {
  private arrowAnimation: SpineAnimation[] = [];

  private heartCount = ARROW_ANIMATIONS.stock0_idle;
  private prevHeartCount = ARROW_ANIMATIONS.stock0_idle;

  private meterKind!: MeterKind;

  constructor() {
    super();

    this.init();
  }

  private init(): void {
    this.containerInit();
    this.eventInit();
    this.animationInit();
  }

  private containerInit(): void {
    this.visible = false;
    this.position.set(ArrowPram.CONTAINER_LANDSCAPE_POS_X, ArrowPram.CONTAINER_LANDSCAPE_POS_Y);
  }

  private eventInit(): void {
    eventManager.addListener(EventTypes.INIT_METER_SET, this.initView.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.HEART_SET_ADD, this.heartSet.bind(this));
    eventManager.addListener(EventTypes.HEART_SHOT, this.heartShot.bind(this));
    eventManager.addListener(EventTypes.FREE_SPINS_DISPLAY_SHOW, this.setDisplay.bind(this));
  }

  private initView(_setMultiPlierNum: number, setKind: MeterKind) {
    this.meterKind = setKind;
  }

  private animationInit(): void {
    for (let i = 0; i < ARROW_ANIMATIONS_STEPS.length; i++) {
      this.arrowAnimation[i] = new SpineAnimation({}, ARROW_ANIMATION_NAME);
      this.arrowAnimation[i]!.setAnimation(ARROW_ANIMATIONS_STEPS[i]!, true);
      this.arrowAnimation[i]!.start();
      this.addChild(this.arrowAnimation![i]!.spine);
      this.arrowAnimation![i]!.spine.alpha = 0;
    }
  }

  private startAnimation(heartCount: number): void {
    const fadeTime = 150;
    const fadeIn = new Tween({
      object: this.arrowAnimation![heartCount]!.spine,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: heartCount === ARROW_ANIMATIONS.stock0_idle ? 0 : fadeTime,
    });
    const fadeOut = new Tween({
      object: this.arrowAnimation![this.prevHeartCount]!.spine,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: fadeTime,
    });
    fadeIn.addOnComplete(() => {
      fadeOut.start();
    });
    fadeIn.start();
    this.prevHeartCount = heartCount;
  }

  private heartSet(): void {
    this.heartCount++;
    if (this.heartCount <= ARROW_ANIMATIONS.stock6_idle) {
      this.startAnimation(this.heartCount);
      if (this.heartCount === ARROW_ANIMATIONS.stock6_idle) {
        this.heartCount = ARROW_ANIMATIONS.stock0_idle;
      }
    } else {
      this.heartCount = ARROW_ANIMATIONS.stock0_idle;
    }
  }

  private heartShot(): void {
    AudioApi.play({ type: ISongs.Lightning });
    this.heartCount = ARROW_ANIMATIONS.stock0_idle;
    this.startAnimation(this.heartCount);
  }

  private changeMode(_settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }): void {
    this.visible = false;
    this.heartCount = ARROW_ANIMATIONS.stock0_idle;
  }

  private setDisplay(visible: boolean): void {
    if (this.meterKind === MeterKind.SlotId_D_J || this.meterKind === MeterKind.SlotId_B_H_G) {
      const delay = Tween.createDelayAnimation(1);
      delay.addOnComplete(() => {
        this.visible = visible;
      });
      delay.start();
      this.heartCount = ARROW_ANIMATIONS.stock0_idle;
      this.startAnimation(ARROW_ANIMATIONS.stock0_idle);
    } else {
      this.visible = false;
      this.heartCount = ARROW_ANIMATIONS.stock0_idle;
    }
  }
}

export default Arrow;
