import { SlotId } from '../../config';

export type bonusData = {
  gameMode: GameMode;
  spSymbol: SlotId;
};

export enum GameMode {
  REGULAR,
  FREE_SPIN_B,
  FREE_SPIN_C,
  FREE_SPIN_D,
  FREE_SPIN_E,
  FREE_SPIN_F,
  FREE_SPIN_G,
  FREE_SPIN_H,
  FREE_SPIN_I,
  FREE_SPIN_J,
  BUY_FEATURE,
}

export const BonusDataByBonusId: Record<string, bonusData> = {
  'd8b1bd10-0b56-40d3-a3e9-c63475ebec3b': {
    gameMode: GameMode.FREE_SPIN_B,
    spSymbol: SlotId.B,
  },
  '20e3c5f4-8d05-434e-b01c-99948586b1b8': {
    gameMode: GameMode.FREE_SPIN_C,
    spSymbol: SlotId.C,
  },
  'abc84a98-f228-4f2f-9d87-bd5829647a1d': {
    gameMode: GameMode.FREE_SPIN_D,
    spSymbol: SlotId.D,
  },
  '7b00914b-736d-4fa1-86dc-2b4ccf89030a': {
    gameMode: GameMode.FREE_SPIN_E,
    spSymbol: SlotId.E,
  },
  '642782fc-f719-425b-b180-5121799b4b68': {
    gameMode: GameMode.FREE_SPIN_F,
    spSymbol: SlotId.F,
  },
  'ad67d27e-716b-4aa4-a956-d466bace933a': {
    gameMode: GameMode.FREE_SPIN_G,
    spSymbol: SlotId.G,
  },
  '3599e9f9-64fa-42e6-b8ce-88116bd9debe': {
    gameMode: GameMode.FREE_SPIN_H,
    spSymbol: SlotId.H,
  },
  '935ba372-52ac-438f-801c-bb566b8ccc5d': {
    gameMode: GameMode.FREE_SPIN_I,
    spSymbol: SlotId.I,
  },
  '50bd0a31-142a-4c4d-91dd-4aea76bc480b': {
    gameMode: GameMode.FREE_SPIN_J,
    spSymbol: SlotId.J,
  },
  'f2a98f8e-e3ae-44cb-8c7e-f7908b462883': {
    gameMode: GameMode.BUY_FEATURE,
    spSymbol: SlotId.WL,
  },
};

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '18b0ccce-6a2f-4944-aa3c-d5573372e934',
  [GameMode.FREE_SPIN_B]: 'f7ecf14b-1eb3-41fc-95c4-76f5917211e9',
  [GameMode.FREE_SPIN_C]: 'dd85428d-d43c-48f3-95f4-db6921dfdda0',
  [GameMode.FREE_SPIN_D]: '3cb07240-7bd2-4961-8152-e57ab0474ae6',
  [GameMode.FREE_SPIN_E]: 'eee81757-7e66-467b-a2b8-ad964ab65e6c',
  [GameMode.FREE_SPIN_F]: '04f3ec6f-778c-4e7e-94f8-adefeabbe3fb',
  [GameMode.FREE_SPIN_G]: '49840c0d-0cd4-448a-8dc8-500a5665a652',
  [GameMode.FREE_SPIN_H]: '9281802d-64e7-4ab9-a8a5-173f647bdb61',
  [GameMode.FREE_SPIN_I]: '6af378fc-128d-4649-baea-f3e52e139a80',
  [GameMode.FREE_SPIN_J]: 'c0bf0a16-9a71-4952-8dbf-fe17cf7a6af5',
  [GameMode.BUY_FEATURE]: '1e529243-ac52-4c0a-8579-2bad78fa1544',
};
