import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getSlotGql } from '../../gql/query';
import { GameMode, reelSets } from '../../slotMachine/config/bonusInfo';
import { findSubstituteCoinAmount, getNonNullableValue } from '../../utils';

export const useSlotData = () => {
  const getSlotData = async () => {
    const slotData = await client.query({
      query: getSlotGql,
      variables: { input: { id: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    const { slot } = slotData.data;

    setSlotConfig({
      ...setSlotConfig(),
      clientSettings: slot!.clientSettings,
      icons: slot!.icons,
      reels: slot!.reels,
      lines: slot!.lines,
      lineSet: slot!.lineSets![0]!,
      settings: slot!.settings,
    });

    // todo add logic to pick gamemode and reelsetid
    setReelSetId(slot!.reels!.find((reelSet) => reelSet!.type === 'DEFAULT')?.id || reelSets[GameMode.REGULAR]);
    const lastResult = setUserLastBetResult();
    let coinValue;
    let coinAmount;
    if (setBrokenGame() || setBrokenBuyFeature()) {
      const currentBonus = getNonNullableValue(setCurrentBonus());
      coinValue = currentBonus.coinValue;
      coinAmount = currentBonus.coinAmount;
    } else {
      const lastBetCoinAmount = setUserLastBetResult() ? setUserLastBetResult()!.coinAmount : 1;
      coinAmount = findSubstituteCoinAmount(
        lastBetCoinAmount,
        getNonNullableValue(slot?.clientSettings?.coinAmounts.default),
      );
      coinValue = getNonNullableValue(
        slot?.clientSettings?.coinValues.find((value) => value?.code === setCurrency())?.variants![0],
      );
    }
    setCoinValue(coinValue);
    setCoinAmount(coinAmount);
    setWinAmount(lastResult?.result.winCoinAmount);
    setBetAmount(setCoinAmount() * slot!.lineSets![0]!.coinAmountMultiplier);
  };
  return { getSlotData };
};
