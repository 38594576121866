export const spineFile = [
  'acquisition',
  'freespin_meter',
  'freespin_ui_flash',
  'stock',
  'symbol_all',
  'symbol_expand',
  'symbol_lottery',
  'totalwin_flash',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  acquisition: {
    skins: 'default';
    animations:
      | 'acquisition_c1'
      | 'acquisition_m/acquisition_m1'
      | 'acquisition_m/acquisition_m2'
      | 'acquisition_m/acquisition_m3'
      | 'acquisition_m/acquisition_m4'
      | 'acquisition_m/acquisition_m5'
      | 'acquisition_m/acquisition_m6'
      | 'acquisition_r/acquisition_r1'
      | 'acquisition_r/acquisition_r2'
      | 'acquisition_r/acquisition_r3'
      | 'acquisition_r/acquisition_r4'
      | 'acquisition_r/acquisition_r5'
      | 'acquisition_r/acquisition_r6'
      | 'acquisition_r/acquisition_r7'
      | 'acquisition_r/acquisition_r8'
      | 'acquisition_r/acquisition_r9'
      | 'acquisition_r/acquisition_r10'
      | 'acquisition_r/acquisition_r11'
      | 'acquisition_r/acquisition_r12'
      | 'acquisition_r/acquisition_r13'
      | 'acquisition_r/acquisition_r14'
      | 'acquisition_r/acquisition_r15';
  };
  freespin_meter: {
    skins: 'default';
    animations:
      | 'freespin_1meter_none'
      | 'freespin_1meter_stepup1'
      | 'freespin_1meter_stepup2'
      | 'freespin_3meter_none'
      | 'freespin_3meter_stepup1'
      | 'freespin_3meter_stepup2'
      | 'freespin_3meter_stepup3'
      | 'freespin_3meter_stepup4'
      | 'freespin_base'
      | 'freespin_base_none'
      | 'freespin_max';
  };
  freespin_ui_flash: { skins: 'default'; animations: 'freespin_ui_flash' };
  stock: {
    skins: 'default';
    animations:
      | 'stock0_idle'
      | 'stock1_idle'
      | 'stock2_idle'
      | 'stock3_idle'
      | 'stock4_idle'
      | 'stock5_idle'
      | 'stock6_idle';
  };
  symbol_all: {
    skins: 'default';
    animations:
      | 'symbol_a_announce'
      | 'symbol_a_win'
      | 'symbol_b3_win'
      | 'symbol_b_announce_sp'
      | 'symbol_b_win'
      | 'symbol_c3_win'
      | 'symbol_c_announce_sp'
      | 'symbol_c_win'
      | 'symbol_d3_win'
      | 'symbol_d_announce_sp'
      | 'symbol_d_win'
      | 'symbol_e3_win'
      | 'symbol_e_announce_sp'
      | 'symbol_e_win'
      | 'symbol_f3_win'
      | 'symbol_f_announce_sp'
      | 'symbol_f_win'
      | 'symbol_g3_win'
      | 'symbol_g_announce_sp'
      | 'symbol_g_win'
      | 'symbol_h3_win'
      | 'symbol_h_announce_sp'
      | 'symbol_h_win'
      | 'symbol_i3_win'
      | 'symbol_i_announce_sp'
      | 'symbol_i_win'
      | 'symbol_j3_win'
      | 'symbol_j_announce_sp'
      | 'symbol_j_win';
  };
  symbol_expand: { skins: 'default'; animations: 'symbol_expand1' | 'symbol_expand2' | 'symbol_expand3' };
  symbol_lottery: { skins: 'default'; animations: 'idle' | 'idle_ex' | 'lottery' | 'lottery_set' };
  totalwin_flash: { skins: 'default'; animations: 'totalwin_flash' };
}
