import { ITextStyle } from 'pixi.js';

import { Variables } from '../../config';

const font = Variables.FONT_FAMILY;

export const replayTextStyle: Partial<ITextStyle> = {
  fill: 0xffffff,
  fontSize: 32,
  fontFamily: font,
};

export const REPLAY_TEXT_MARGIN = 40;
