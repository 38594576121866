import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, MAPPED_SP_SYMBOLS, SlotId } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { BgmSoundTypes } from '../../global.d';
import {
  setBrokenGame,
  setCurrentBonus,
  setCurrentStage,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinModeSceneChange,
  setIsOpenedMessageBanner,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
} from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { getSpSymbolByBonusId, getSpinResult5x3 } from '../../utils/helper';
import { initSetMeter, updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BACKGROUND_INSURANCE_SCALE, BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import { EventTypes, REELS_AMOUNT, SAFE_AREA_LANDSCAPE_WIDTH, eventManager } from '../config';

import {
  ANIMATIONS,
  ANIMATIONS_STEPS,
  BOOK_ANIMATION_POS_X,
  BOOK_ANIMATION_POS_Y,
  DISPLAY_SHOW_DELAY,
  FreeSpinDescTextStyles,
  FreeSpinNumTextStyles,
  FreeSpinTitleTextStyles,
  PressStartTextStyles,
  SP_SYMBOL_DISPLAY_WAIT,
  SP_SYMBOL_LOT_BASE_ALPHA,
  SP_SYMBOL_LOT_BASE_FADE_OUT_TIME,
  SP_SYMBOL_PRESS_START_DELAY,
  SP_SYMBOL_TEXT_MARGIN,
} from './config';

class SpSymbolLog extends PIXI.Container {
  private bookAnimationIdle: SpineAnimation | undefined = undefined;

  private bookAnimation: SpineAnimation | undefined = undefined;

  private animationStepIndex: number;

  private hasForceStop = false;

  private hasStop: boolean;

  private spSymbol: PIXI.Sprite;

  private pressStartText: PIXI.Text;

  private freeSpinsNum: PIXI.Text;

  private freeSpinsText: PIXI.Text;

  private freeSpinsDesc1: PIXI.Text;

  private spSymbolDisplayDelay: Animation | null = null;

  private spSymbol_lottery_window: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolLotteryWindow));

  private rect: PIXI.Graphics;

  constructor() {
    super();

    this.visible = false;
    this.animationStepIndex = ANIMATIONS.lottery;
    this.hasStop = false;
    this.position.set(BOOK_ANIMATION_POS_X, BOOK_ANIMATION_POS_Y);
    this.sortableChildren = true;
    this.spSymbol = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.symbolB));
    this.spSymbol_lottery_window.pivot.set(0.5);
    this.spSymbol_lottery_window.position.set(-this.spSymbol_lottery_window.width / 2, 300);
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(0x000000);
    this.rect.drawRect(0, 0, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT * BACKGROUND_INSURANCE_SCALE);
    this.rect.position.set(BACKGROUND_SIZE_WIDTH / 2, -BACKGROUND_SIZE_HEIGHT / 2);
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;
    this.addChild(this.rect);
    this.addChild(this.spSymbol_lottery_window);
    this.initBookAnimationIdle();
    this.initBookAnimation();
    this.pressStartText = this.initText();
    this.freeSpinsNum = new PIXI.Text(i18n.t<string>('spSymbolLot.freeSpinNum'), FreeSpinNumTextStyles);
    this.freeSpinsText = new PIXI.Text(i18n.t<string>('spSymbolLot.freeSpins'), FreeSpinTitleTextStyles);
    this.freeSpinsDesc1 = new PIXI.Text(i18n.t<string>('spSymbolLot.spSymbolDescFix'), FreeSpinDescTextStyles);
    this.freeSpinsNum.resolution = 1;
    this.freeSpinsText.resolution = 1;
    this.freeSpinsDesc1.resolution = 1;
    this.pivot.set(0.5);
    this.freeSpinsNum.anchor.set(0.5);
    this.freeSpinsText.anchor.set(0.5);
    this.freeSpinsDesc1.anchor.set(0.5);
    this.freeSpinsText.position.set(200, 50);

    eventManager.addListener(EventTypes.SP_SYMBOL_LOT_START, this.spSymbolLotStart.bind(this));
    eventManager.addListener(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT, this.handleClick.bind(this));
    eventManager.addListener(EventTypes.SP_SYMBOL_IF_VISIBLE, this.spSymbolIfVisible.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));

    this.interactive = true;
    this.on('click', () => {
      this.handleClick();
    });
    this.on('touchstart', () => {
      this.handleClick();
    });
  }

  private handleClick(): void {
    if (setIsFreeSpinModeSceneChange()) return;
    if (this.bookAnimationIdle!.getSpine().visible) {
      this.bookAnimationIdle!.getSpine().visible = false;
      this.pressStartText.visible = false;
      this.startBookAnimation('lottery');
    } else if (this.animationStepIndex === ANIMATIONS.idle_ex) {
      this.spSymbolDisplayDelay?.skip();
      this.onCompleteBookAnimation();
    }
  }

  private initText(): PIXI.Text {
    const pressStart = new PIXI.Text(i18n.t<string>('startText'), PressStartTextStyles);
    pressStart.resolution = 1;
    pressStart.anchor.set(0.5, 0.5);
    updateTextScale(pressStart, SAFE_AREA_LANDSCAPE_WIDTH - 100, 150);
    return pressStart;
  }

  private initBookAnimationIdle(): void {
    this.bookAnimationIdle = new SpineAnimation({}, 'symbol_lottery');
    this.bookAnimationIdle.addOnStart(() => {
      this.addChild(this.bookAnimationIdle!.spine);
      this.addChild(this.pressStartText);
    });
  }

  private initBookAnimation(): void {
    this.bookAnimation = new SpineAnimation({}, 'symbol_lottery');
    this.bookAnimation.addOnStart(() => {
      this.addChild(this.bookAnimation!.spine, this.freeSpinsNum, this.freeSpinsText, this.freeSpinsDesc1);
    });
  }

  private startBookAnimationIdle(anim: SpineInterface['symbol_lottery']['animations']): void {
    this.bookAnimationIdle!.setAnimation(anim, true);
    if (this.bookAnimationIdle?.getSpine()) this.bookAnimationIdle.getSpine().visible = true;
    this.bookAnimationIdle!.start();
  }

  private startBookAnimation(anim: SpineInterface['symbol_lottery']['animations']): void {
    let loop = false;
    if (anim === ANIMATIONS_STEPS[ANIMATIONS.idle_ex]) {
      loop = true;
      setTimeout(() => {
        this.freeSpinsNum.position.set(200, -40);
        this.freeSpinsDesc1.position.set(
          0,
          this.spSymbol_lottery_window.y +
            (this.spSymbol_lottery_window.height * this.spSymbol_lottery_window.scale.y) / 2,
        );
        this.freeSpinsNum.visible = true;
        this.freeSpinsText.visible = true;
        this.freeSpinsDesc1.visible = true;
        this.spSymbol_lottery_window.visible = true;
      }, 1);
      this.spSymbolDisplayDelay = Tween.createDelayAnimation(SP_SYMBOL_DISPLAY_WAIT);
      this.spSymbolDisplayDelay.addOnComplete(() => {
        this.onCompleteBookAnimation();
      });
      this.spSymbolDisplayDelay.start();
    }

    this.bookAnimation!.setAnimation(anim, loop);
    if (this.bookAnimation?.getSpine()) this.bookAnimation.getSpine().visible = true;
    if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery]) {
      this.bookAnimation!.start();
    }
    this.bookAnimation!.complete = [];
    this.bookAnimation!.addOnComplete(() => {
      if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery]) {
        this.onCompleteBookAnimation();
      } else if (anim === ANIMATIONS_STEPS[ANIMATIONS.lottery_set]) {
        this.onCompleteBookAnimation();
        setIsOpenedMessageBanner(false);
      }
    });

    this.bookAnimation!.getSpine().hackTextureBySlotName(
      'SPsymbols',
      this.spSymbol.texture,
      this.spSymbol.texture.frame,
    );

    this.bookAnimation!.getSpine().state.addListener({
      event: function (_t, e) {
        if (e.data.name === 'page_start') {
          AudioApi.play({ type: ISongs.Book });
        } else if (e.data.name === 'page_end') {
          AudioApi.stop({ type: ISongs.Book });
          AudioApi.play({ type: ISongs.MultiUp_1 });
        } else if (e.data.name === 'symbol_set') {
          eventManager.emit(EventTypes.SP_SYMBOL_IF_VISIBLE);
          setIsOpenedMessageBanner(false);
          BgmControl.playBgm(BgmSoundTypes.BB);
          setCurrentStage(2);
        }
      },
    });
  }

  private spSymbolIfVisible(): void {
    if (!this.visible) return;
    this.hasStop = true;

    const freeSpinsBonus = SlotMachine.getInstance().getFreeSpinBonus();
    const slotConfig = setSlotConfig() as RecursiveNonNullable<ISlotConfig>;

    if (setBrokenGame()) {
      eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT, setCurrentBonus().rounds, 0, false);
      const spinResult = getSpinResult5x3({
        reelPositions: setPrevReelsPosition().slice(0, REELS_AMOUNT),
        reelSet: slotConfig.reels.find((reelSet) => reelSet.id === setReelSetId())!,
        icons: slotConfig.icons,
      });
      initSetMeter(getSpSymbolByBonusId(setCurrentBonus().bonus!.id), spinResult);
    } else {
      initSetMeter(getSpSymbolByBonusId(freeSpinsBonus!.bonus!.id));
    }
    AudioApi.play({ type: ISongs.MeterLit });
    eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_FLASH);

    const delay = Tween.createDelayAnimation(DISPLAY_SHOW_DELAY);
    delay.addOnComplete(() => {
      eventManager.emit(EventTypes.FREE_SPINS_DISPLAY_SHOW, true);
    });
    delay.start();

    this.visible = false;
  }

  private onCompleteBookAnimation(): void {
    this.animationStepIndex += 1;
    if (this.hasForceStop) {
      this.hasForceStop = false;
    }

    if (this.animationStepIndex === ANIMATIONS.lottery_set) {
      AudioApi.play({ type: ISongs.EffectMove });
      const animation = new AnimationGroup();
      animation.addAnimation(this.getSceneChangeOutAnimation(SP_SYMBOL_LOT_BASE_FADE_OUT_TIME));
      animation.start();
    }

    if (this.animationStepIndex === ANIMATIONS_STEPS.length) {
      this.hasStop = true;
      this.visible = false;
    }

    if (!this.hasStop) {
      this.startBookAnimation(ANIMATIONS_STEPS[this.animationStepIndex]!);
    }
  }

  private removeAnimation(): void {
    if (!this.hasStop && this.animationStepIndex === ANIMATIONS.lottery) {
      this.hasForceStop = true;
      this.bookAnimation?.skip();
      this.animationStepIndex = 2;
      this.startBookAnimation(ANIMATIONS_STEPS[this.animationStepIndex]!);
    }
  }

  private spSymbolLotStart(): void {
    setIsOpenedMessageBanner(true);
    this.visible = true;
    this.hasStop = false;
    this.animationStepIndex = 0;
    this.bookAnimation!.getSpine().visible = false;
    this.pressStartText.visible = true;
    this.freeSpinsNum.visible = false;
    this.freeSpinsText.visible = false;
    this.freeSpinsDesc1.visible = false;
    this.spSymbol_lottery_window.visible = false;
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;
    this.rect.visible = true;
    this.startBookAnimationIdle('idle');

    let slot: SlotId;
    if (setBrokenGame()) {
      slot = getSpSymbolByBonusId(setCurrentBonus().bonus!.id)!;
    } else {
      slot = getSpSymbolByBonusId(setNextResult()!.bet.data.features.gameRoundStore.bonusId);
    }

    this.spSymbol.texture = PIXI.Texture.from(MAPPED_SP_SYMBOLS[slot]);
    eventManager.emit(EventTypes.SET_SP_SYMBOL, slot);

    if (setIsContinueAutoSpinsAfterFeature()) {
      const delay = Tween.createDelayAnimation(SP_SYMBOL_PRESS_START_DELAY);
      delay.addOnComplete(() => {
        eventManager.emit(EventTypes.SPACE_KEY_CLOSE_SP_SYMBOL_LOT);
      });
      delay.start();
    }

    switch (slot) {
      // switch (SlotMachine.getInstance().nextResult?.bet.data.features.gameRoundStore.id) {
      case SlotId.E:
      case SlotId.I:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescNon');
        break;
      case SlotId.C:
      case SlotId.F:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescFix');
        break;
      case SlotId.B:
      case SlotId.D:
      case SlotId.G:
      case SlotId.H:
      case SlotId.J:
        this.freeSpinsDesc1.text = i18n.t<string>('spSymbolLot.spSymbolDescUp');
        break;
    }

    if (this.freeSpinsDesc1.width >= this.spSymbol_lottery_window.width - SP_SYMBOL_TEXT_MARGIN) {
      this.freeSpinsDesc1.width = this.spSymbol_lottery_window.width - SP_SYMBOL_TEXT_MARGIN;
    }

    this.freeSpinsNum.text = i18n.t<string>('spSymbolLot.freeSpinNum', {
      spin: setCurrentBonus().rounds,
    });
    this.freeSpinsText.text = i18n.t<string>('spSymbolLot.freeSpins');
  }

  private getSceneChangeOutAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: SP_SYMBOL_LOT_BASE_ALPHA,
      target: 0,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => 1 - Math.pow(1 - n, 7),
    });
    return animation;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    _pivotX: number,
    _pivotY: number,
  ): void {
    this.rect.clear();
    this.rect.beginFill(0x000000);
    this.rect.drawRect(
      0,
      0,
      SlotMachine.getInstance().background.width * (1 / scale),
      SlotMachine.getInstance().background.height * (1 / scale) * BACKGROUND_INSURANCE_SCALE,
    );
    this.rect.position.set(-this.rect.width / 2, -this.rect.height / 2);
    this.rect.alpha = SP_SYMBOL_LOT_BASE_ALPHA;

    updateTextScale(this.pressStartText, SlotMachine.getInstance().background.width - 100, 150);
  }
}

export default SpSymbolLog;
