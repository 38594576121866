import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { setIsSoundOn, setProgress } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage } from '../../utils';
import bgmControl from '../bgmControl/bgmControl';
import { EventTypes, eventManager } from '../config';
import SoundBtn from '../controlButtons/soundBtn';
import { UiButton } from '../ui/uiButton';

import Carousel from './Carousel/index';

class IntroScreen {
  private readonly application: PIXI.Application;

  private container: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: PIXI.Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new PIXI.Container();

  private playBtn: UiButton;

  private soundBtn: SoundBtn;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: PIXI.Application) {
    this.application = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);
    this.application.stage.addChild(this.backgroundContainer);
    this.container = new PIXI.Container();
    this.controlsContainer = new PIXI.Container();
    this.carousel = new Carousel(introContents);
    this.container.addChild(this.carousel);
    this.playBtn = this.initPlayBtn();
    this.soundBtn = new SoundBtn();
    this.controlsContainer.addChild(this.playBtn, this.soundBtn);
    this.container.addChild(this.controlsContainer);
    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      AudioApi.setSoundState(setIsSoundOn());
    });
  }

  private initPlayBtn(): UiButton {
    const btn = new UiButton('play');
    btn.btn.anchor.set(1, 0.5);
    btn.interactive = true;

    const clickCallback = () => {
      AudioApi.restrictionChangedOnIntroScreen = true;

      bgmControl.handleChangeRestriction();

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    btn.on('click', clickCallback);
    btn.on('touchend', clickCallback);

    return btn;
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);
    this.setBgSize(width, height);
    this.carousel.setSize(width, height, 15);
    this.container.y = 0;

    this.playBtn.btn.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;
    this.playBtn.setSize(calcPercentage(width, 20));

    this.soundBtn.btn.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));

    if (isPortraitMode) {
      this.soundBtn.btn.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.btn.anchor.set(0.5, 0);
      this.playBtn.y = this.carousel.getBounds().y + this.carousel.height + 30;
      this.playBtn.x = width / 2;
      this.playBtn.setSize(calcPercentage(height - calcPercentage(height, 7.5), 17));
    }

    this.application.renderer.resize(width, height);
  }

  private destroy(): void {
    this.container.destroy();
    this.backgroundContainer.destroy();
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
