import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_STOP_ANIMATIONS, SlotId } from '../../config';
import { ISettledBet } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setIsAnticipation } from '../../gql/cache';
import { destroySpine, getGameModeByBonusId } from '../../utils';
import { getNonNullableValue, isScatter } from '../../utils/helper';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  DEFAULT_SPRITE_COLOR,
  EventTypes,
  NOT_WIN_LINE_SPRITE_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { GameMode } from '../config/bonusInfo';
import { IWinLine, Icon } from '../d';
import ReelsFrame from '../reels/frame/reelsFrame';

import {
  BASE_SLOT_SPINE_ANIMATE_PRIORITY,
  REEL_FRAME_PRIORITY,
  STOP_SPECIAL_SYMBOL_PRIORITY,
  WILD_SPINE_ANIMATE_PRIORITY,
} from './config';
import { SpineAnimateSlot } from './spineAnimateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSymbols: SpineAnimateSlot[] = [];

  public animation?: AnimationChain | undefined;

  private frame: ReelsFrame;

  private gameMode: GameMode;

  private spSymbol: SlotId | undefined;

  private symbolSpineContainer: Spine[] = [];

  private symbolSpriteContainer: PIXI.Sprite[] = [];

  constructor() {
    super();
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonus!.id) : GameMode.REGULAR;
    this.sortableChildren = true;
    this.frame = new ReelsFrame();
    this.addChild(this.frame);

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT; i++) {
      this.symbolSpineContainer![i] = new Spine(PIXI.Loader.shared.resources['symbol_all']!.spineData!);
      this.symbolSpriteContainer![i] = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.WL]));
    }

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.START_GET_AMOUNT_WIN, this.skipStopSymbolAnimations.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.onSetupBetResult.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.slotSpritesColorReset.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlotsDisplay.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
    });
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.gameMode = settings.mode;
    });

    eventManager.on(EventTypes.SET_SP_SYMBOL, (spSymbol) => {
      this.spSymbol = spSymbol;
    });
    this.frame.zIndex = 20;
    this.frame.visible = false;
  }

  private clearSymbols(): void {
    this.slotSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private initSymbols(spinResult: Icon[]): void {
    this.clearSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = new SpineAnimateSlot(
          spinResult[i * REELS_AMOUNT + j]!.id,
          j * SLOTS_PER_REEL_AMOUNT + i,
          this.symbolSpineContainer![i * REELS_AMOUNT + j]!,
          this.symbolSpriteContainer![i * REELS_AMOUNT + j]!,
        );
        symbol.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private showStopSlotsDisplay(spinResult: Icon[]) {
    this.animation?.skip();
    this.animation = undefined;
    this.initSymbols(spinResult!);
    this.slotSymbols.forEach((symbol) => (symbol.visible = true));
  }

  private onStartSpin() {
    this.slotSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
    this.skipStopSymbolAnimations();
  }

  private onAnticipationStart(): void {
    this.slotSymbols.forEach((slot) => {
      if (isScatter(slot.slotId)) {
        slot.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        slot.setTint(ANTICIPATION_SLOTS_TINT);
      }
    });
  }

  private onSetupBetResult(): void {
    this.initSymbols(SlotMachine.getInstance().spinResult);
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const symbol = this.slotSymbols[i * REELS_AMOUNT + reelId!]!;
      symbol.visible = true;
      symbol.startStopAnimation();
    }
    this.startOnSymbolsStopAnimations(reelId);
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    if (reelId === 0) this.stopSymbolAnimations = [];

    const spinResult = SlotMachine.getInstance().spinResult;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId = spinResult[i * REELS_AMOUNT + reelId]!.id;

      if (
        (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId] && slotId === SlotId.WL) ||
        (slotId === this.spSymbol && this.gameMode != GameMode.REGULAR)
      ) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, animationData.src);
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          eventManager.emit(EventTypes.SET_REEL_FRAME_VISIBLE, false);
          this.frame.visible = true;
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
          this.addChild(animation.getSpine());
          animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
          animation.setAnimation(animationData.animation!, false);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = false;
        });
        dummy.addOnComplete(() => {
          destroySpine(animation);
          if (reelId === 1) {
            setIsAnticipation(false);
          }
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        dummy.addOnSkip(() => {
          destroySpine(animation);
          setIsAnticipation(false);
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        dummy.addOnChange(() => {
          // const anticipationLines: number[] = [];
          // setAnticipationLine().forEach((anticipationLine) => {
          //   setSlotConfig().lines[anticipationLine]!.forEach((icon, index) => {
          //     if (index != 1) anticipationLines.push(icon);
          //   });
          // });
          if (!setIsAnticipation()) {
            animation.spine.tint = 0xffffff;
          }
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean, isScatter: boolean, isExpand: boolean) {
    this.showWin(nextResult, isScatter, isExpand);
    eventManager.emit(EventTypes.SET_REEL_FRAME_VISIBLE, false);
    this.frame.visible = true;
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.slotSymbols.forEach((symbol) => symbol.skip());
    this.slotSpritesColorReset();
    eventManager.emit(EventTypes.SET_REEL_FRAME_VISIBLE, true);
    this.frame.visible = false;
  }

  private showWin(nextResult: ISettledBet, isScatter: boolean, isExpand: boolean): void {
    const { paylines } = nextResult!;
    const { spinResult } = SlotMachine.getInstance();
    const winpostion = [];
    let nullCount = 0;
    let j = 0;

    if (isExpand) {
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, false);
      this.slotSpritesColorChange();
      return;
    }

    for (let i = 0; i < paylines.length; i++) {
      if (paylines[i]!.lineId === null) {
        if (isScatter && nullCount) {
          winpostion[j] = paylines[i]?.winPositions;
          paylines[i]!.winPositions = [];
          j++;
        } else if (!isScatter) {
          winpostion[j] = paylines[i]?.winPositions;
          paylines[i]!.winPositions = [];
          j++;
        }

        if (!isScatter) {
          if (paylines.length === 2) {
            paylines[0]!.winPositions = winpostion[0]!;
            return;
          }
        }
        nullCount++;
      }
    }

    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      // eventManager.emit(EventTypes.SHOW_TINT, false);
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
    });

    const set = new Set<number>();
    paylines.forEach((payLine) => {
      payLine!.winPositions!.forEach((position) => {
        set.add(position!);
      });
    });

    {
      const allSlotsHighlight = this.highlightSlots(Array.from(set));
      allSlotsHighlight.addOnStart(() => {
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
        this.setWinSlotTint(Array.from(set));
      });
      allSlotsHighlight.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        // eventManager.emit(EventTypes.SHOW_TINT, false);
      });
      allSlotsHighlight.addOnSkip(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        // eventManager.emit(EventTypes.SHOW_TINT, false);
      });
      this.animation.appendAnimation(allSlotsHighlight);
    }

    {
      const eachSlotsHighlight = this.createHighlightChainAnimation(getNonNullableValue(paylines), true);
      this.animation.appendAnimation(eachSlotsHighlight);
    }
    let k = 0;
    for (let i = paylines.length - j; i < paylines.length; i++) {
      paylines[i]!.winPositions = winpostion[k]!;
      k++;
    }
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);
      chain.addOnStart(() => {
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        this.setWinSlotTint(payline.winPositions);
      });

      chain.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
        this.slotSpritesColorChange();
      });

      // chain.addOnSkip(() => {
      //   eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      // });

      animationChain.appendAnimation(chain);
    });
    return animationChain;
  }

  private slotSpritesColorChange(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    }
  }

  private slotSpritesColorReset(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(DEFAULT_SPRITE_COLOR);
      this.slotSymbols[i]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
    }
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.frame.zIndex = REEL_FRAME_PRIORITY;

    this.slotSymbols.forEach((slot) => {
      slot.setTint(NOT_WIN_LINE_SPRITE_COLOR);
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.setTint(DEFAULT_SPRITE_COLOR);
      if (isScatter(this.slotSymbols[slot]!.slotId)) {
        this.slotSymbols[slot]!.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        this.slotSymbols[slot]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
      }
    });
  }
}

export default SlotsAnimationContainer;
