import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private reelBackTexture = PIXI.Texture.from(ResourceTypes.reelbase);

  private reelBack: PIXI.Sprite;

  constructor() {
    super();
    this.reelBack = this.initReelBack();

    this.addChild(this.reelBack);
  }

  private initReelBack(): PIXI.Sprite {
    const reelback = new PIXI.Sprite();
    reelback.texture = this.reelBackTexture;
    reelback.anchor.set(0.5);
    reelback.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    return reelback;
  }
}

export default ReelsBackgroundContainer;
