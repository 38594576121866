import { setIsAutoSpins, setIsBuyFeaturePurchased } from '../../gql/cache';
import { EventTypes, eventManager } from '../../slotMachine/config';

export function fallBackReelPosition() {
  if (setIsBuyFeaturePurchased()) {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUYFEATURE);
  } else {
    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }
}
